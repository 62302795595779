import React, { Component } from 'react';

class UiEditHome extends Component {

    state = {
        uid: 0,
        title: "",
        zlogin: "",
        zpass: "",
        zsesid: "",
        selected: null,
    }

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.show && this.state.selected == null ){
            let _arr = [];
            this.setState({
                uid: this.props.selected.building.user_id,
                title: this.props.selected.building.title,
                zlogin: this.props.selected.building.zlogin ,
                zpass: this.props.selected.building.zpass,
                zsesid: this.props.selected.building.zbw_sessid,
                selected: this.props.selected
            })
        }
     }

    render() {
        var users = this.props.usersList.map((item, index) => {
            return (
                <option value={item.id}   key={index} >{item.fio}</option>
            )
        });
        if(this.props.show) {
            return (
                <div className={"alert-wrap " + (this.props.show ? "show" : "hide")}>
                    <div className="card modal-card">
                        <div className="modal-card-inner">

                            <h3 className="form-title">Редактирование помещение</h3>

                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Название" value={this.state.title} onChange={(res) => { this.setState({title: res.target.value }) }} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Z-LOGIN" value={this.state.zlogin} onChange={(res) => { this.setState({zlogin: res.target.value }) }} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Z-ID" value={this.state.zsesid} onChange={(res) => { this.setState({zsesid: res.target.value }) }}  />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Z-PASS" value={this.state.zpass} onChange={(res) => { this.setState({zpass: res.target.value }) }} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <select className="form-control" onChange={(res) => { this.setState({uid: res.target.value})}} >
                                            {users}
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <button type="button" className="btn btn-info btn-block mb-2" onClick={() => this.props.saveCallBack(this.state.selected.building.id, this.state.uid , this.state.title, this.state.zlogin, this.state.zpass, this.state.zsesid)}>Сохранить изменения</button>
                            </div>
                            <div className="col-md-4">
                                <button type="button" className="btn btn-secondary btn-block mb-2" onClick={() => this.props.removeCallBack()}>Удалить помещение</button>
                            </div>
                            <div className="col-md-4">
                                <button type="button" className="btn btn-outline-info btn-block" onClick={() => this.props.closeCallBack()}>Отмена</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default UiEditHome;