import React, { Component } from 'react';
import windowSize from 'react-window-size';

import { useParams } from "react-router-dom";

import UiHeader from '../../components/ui/UiHeader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';
import UiRoomCard from '../../components/ui/cards/UiRoomCard.js';
import UiBreadcrumb from '../../components/ui/UiBreadcrumb.js';
import UiDeviceCard from '../../components/ui/cards/UiDeviceCard.js';
import UiScriptCard from '../../components/ui/cards/UiScriptCard.js';

import ENV from '../../services/Env.js'

import { getAllDevices } from '../../services/Devices.js';
import { loadUser, getCitys, getUsers, addUser, removeUser, updateUser } from '../../services/Users.js';
import { getAllRooms, addRoom, getAllPlans, savePlan } from '../../services/Rooms.js';
import UiEditUser from '../../components/ui/modals/UiEditUser.js';

const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);


class Users extends Component {

  state = {
    user: {
      adress: "",
      city: {
        title: ""
      }
    },

    editUserVisible: false,
    contentHeight: this.props.windowHeight - 130,

    citysList: [],
    usersList: [],
    userType: 0,
    userCity: 0,
    userFio: "",

  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let _user = loadUser();
    this.setState({ user: _user }, () => this._load())
    getCitys().then((res) => {
      console.log(res)
      this.setState({ citysList: res.response })
    })
  }

  _load() {
    console.log("_navigator", this.props.match.params.id);
    getUsers().then((res) => {
      console.log(res)
      this.setState({ usersList: res.response })
    })
  }

  _addUser() {
    addUser(this.state.userType, this.state.userFio, this.state.userCity, "").then((res) => {
      console.log(res);
      this._load();
    })
  }

  _updateUser(_fio, _type, _city) {
    updateUser(this.state.selectedId, _fio, _type, _city).then((res) => {
      console.log(res);
      this._load();
    })
  }


  _remove(_id) {
    removeUser(_id).then((res) => {
      console.log(res);
      this._load();
    })
  }

  render() {
    var citys = this.state.citysList.map((item, index) => {
      return (
        <option value={item.id} key={index}>{item.title}</option>
      )
    })

    var list = this.state.usersList.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{item.id}</th>
          <td>{item.login}</td>
          <td>{item.password}</td>
          <td>{item.fio}</td>
          <td>
            {item.type == 0 ? "Администратор" :
              item.type == 1 ? "Менеджер" :
                item.type == 2 ? "Пользователь" :
                  item.type == 3 ? "Ребенок" : ""}
          </td>
          <td className="table-btn-td">
            <div className="table-btn-wrap">
              <button className="btn profile-icon-wrap" onClick={() => {
                this.setState({
                  selectedType: item.type,
                  selectedFio: item.fio,
                  selectedId: item.id,
                  editUserVisible: true,
                });
              }}>
                <img className="profile-icon" src={require("../../assets/img/ui-icons/settings-cog.png")} />
              </button>
            </div>
          </td>
          <td className="table-btn-td">
            {this.state.user.type == 0 ?
              <div className="table-btn-wrap">
                <button className="btn profile-icon-wrap" onClick={() => this._remove(item.id)}>
                  <img className="profile-icon" src={require("../../assets/img/ui-icons/trash.png")} />
                </button>
              </div>
              : null}
          </td>
        </tr>
      )
    });

    return (
      <div ref={this.divPage} id="page">
        <UiEditUser
          show={this.state.editUserVisible}
          userType={this.state.selectedType}
          userFio={this.state.selectedFio}
          citysList={this.state.usersList}
          closeCallBack={() => this.setState({ editUserVisible: false })}
          updateCallBack={(_fio, _type, _city) => {
            this.setState({ editUserVisible: false });
            this._updateUser(_fio, _type, _city);
          }}
          deleteCallBack={() => this._remove(this.state.selectedId)}
        />
        <div className="wrapper">
          <UiHeader />
          <div className="content">
            <UiLeftMenu type={this.state.user.type} fio={this.state.user.fio} activePage={7} />

            <div className="container">

              <div className="card content-card mt-4">
                <div className="card-body" style={{ minHeight: this.state.contentHeight + 'px' }}>

                  <div className="body-inner">
                    <h2 className="body-title">Список Пользователей </h2>
                    <UiBreadcrumb backPress="/" linksList={[{ value: 0, linkPress: "/users", linkText: "Список пользователей" }]} />
                    <div className="body-scroll">
                      <div className="table-wrap">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">

                              <select className="form-control" onChange={(e) => this.setState({ userType: e.target.value })} >
                                <option value="0" >Администратор</option>
                                <option value="1" >Менеджер</option>
                                <option value="2" >Пользователь</option>
                              </select>

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="ФИО" onChange={(e) => this.setState({ userFio: e.target.value })} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="form-control" onChange={(e) => this.setState({ userCity: e.target.value })} >
                                {citys}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            {this.state.user.type == 0 ? <button className="btn btn-info btn-block mb-3" onClick={() => this._addUser()} >Добавить пользователя</button> : null}
                          </div>
                        </div>
                        <div className="table-scroll-wrap">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">id</th>
                                <th scope="col">Логин</th>
                                <th scope="col">Пароль</th>
                                <th scope="col">ФИО</th>
                                <th scope="col">Тип</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {list}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default windowSize(Users);