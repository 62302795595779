import React, { Component } from 'react';

class UiStatisticsCard extends Component {

  state = {
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <div className="stat-card">
        <div className="stat-card-top">
          {this.props.leftIcon ? 
            <img className="stat-card-icon" src={this.props.leftIcon}/>
            :null
          }
          <h3 className="stat-card-title">{this.props.title}</h3>
          {this.props.dimension ? 
            <p><span>{this.props.dimension}</span></p>
            : null
          }
        </div>
        <p className="stat-card-text">{this.props.text}</p>
      </div>
    );
  }

}

export default UiStatisticsCard;