import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import ENV from '../../services/Env.js';
import { loadUser, saveUser, loginUser } from '../../services/Users.js';
import UiHeader from '../../components/ui/UiHeader.js';
import UiAlert from '../../components/ui/modals/UiAlert.js';

const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);

class Login extends Component {

  state = {
    loader: true,
    login: "",
    pass: "",
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let _user = loadUser();
    if (_user != null) {
      this.props.history.push("/")
    }
  }

  login(e) {
    e.preventDefault();
    if(this.state.login.length > 1 && this.state.pass.length > 1){
      loginUser(this.state.login, this.state.pass).then((res) => {
        console.log(res);
        if (res.success) {
          if (res.response) {
            saveUser(res.response);
            if(res.response.type == 0 || res.response.type == 1) {
              this.props.history.push("/houses")
            } else {
              this.props.history.push("/")
            }
           
          } else {
            this.setState({ showAlert: true })
          }
        } else {
          this.setState({ showAlert: true })
        }
      })
    }
   
  }

  render() {
    const phoneWidth = window.innerWidth;
    return (
      <div className="wrapper height-100">
        <UiHeader />
        <UiAlert
          show={this.state.showAlert}
          callBack={() => this.setState({ showAlert: false })}
          title="Внимание!"
          text="Не верный логин\пароль"
          leftBtnText="Ok"
        />
        <div className="content d-flex login-content">
          <div className="container">
            <div className="row">
              <div className="col-sm"></div>
              <div className="col-sm">
                <div className="card mb-5 login-card">
                  <div className="card-body">
                    <h5 className="card-title">Авторизация</h5>
                    <form>
                      <div className="form-group">
                        <label htmlFor="login">Логин</label>
                        <input type="text" className="form-control" id="login" onChange={(res) => this.setState({ login: res.target.value })} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Пароль</label>
                        <input type="password" className="form-control" id="password" onChange={(res) => this.setState({ pass: res.target.value })} />
                      </div>
                      <button type="submit" className="btn btn-block btn-info" onClick={(e) => this.login(e)} >Войти</button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-sm"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;