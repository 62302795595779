import ENV from './Env.js';
import AsyncStorage from "react";

 
export const getAllRooms= (_uid) => {
    return fetch(ENV.API_URL+'/rooms/list/get', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: _uid,
        }) 
      }).then(function(response) {
        return response.json();
      });
}

export const getAllPlans= (_uid) => {
  return fetch(ENV.API_URL+'/rooms/plan/get', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: _uid,
      }) 
    }).then(function(response) {
      return response.json();
    });
}


export const addDeviceToRoom = (_device, _type, _room) => {
  return fetch(ENV.API_URL+'/rooms/device/add', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        device: _device,
        type: _type,
        roomId: _room,
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const removeRoom = (_uid ) => {
  return fetch(ENV.API_URL+'/rooms/list/add', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: _uid,
      }) 
    }).then(function(response) {
      return response.json();
    });
}



export const editRoom = (_uid, _title, _size, _type) => {
  return fetch(ENV.API_URL+'/rooms/list/add', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: _uid,
        title: _title,
        size: _size,
        type: _type
      }) 
    }).then(function(response) {
      return response.json();
    });
}


export const addRoom = (_uid, _title, _size, _type) => {
  return fetch(ENV.API_URL+'/rooms/list/add', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: _uid,
        title: _title,
        size: _size,
        type: _type
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const savePlan = (_uid, _data) => {
  return fetch(ENV.API_URL+'/rooms/plan/add', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: _uid,
        data: _data
      }) 
    }).then(function(response) {
      return response.json();
    });
}



 
 