import React, { Component } from 'react';
class UiRoomCard extends Component {
  state = {}
  constructor(props) {
    super(props);
  }
  componentDidUpdate(prevProps, prevState) { }

  _getTitleType(_title, type){
    let title = "";
    if(title != null){
      if(type == 0) title = "Гостиная";
      if(type == 1) title = "Кухня";
      if(type == 2) title = "Спальня";
      if(type == 3) title = "Детская";
      if(type == 4) title = "Коридор";
      if(type == 5) title = "Ванная";
      if(type == 6) title = "Подсобка";
      if(type == 7) title = "Свободная";
      if(type == 8) title = "Кабинет";
      if(type == 9) title = "Столовая";
      if(type == 10) title = "Балкон/лоджия";
      if(type == 11) title = "Сад";
      if(type == 12) title = "Офис";
    } else {
      title = _title;
    }
    return title;
  }

  render() {

    return (
      <a href={"/room/"+this.props.roomId} className="card room-card">
        <div className="room-card-img-wrap">
          {this.props.type == 0 ? 
            <img className="room-card-img" src={require("../../../assets/img/rooms/1_1.jpg")} />
            :
            (this.props.type == 1 ? 
              <img className="room-card-img" src={require("../../../assets/img/rooms/3_1.jpg")} />
              :
              (this.props.type == 2 ? 
                <img className="room-card-img" src={require("../../../assets/img/rooms/2_1.jpg")} />
                :
                (this.props.type == 3 ? 
                  <img className="room-card-img" src={require("../../../assets/img/rooms/6_1.jpg")} />
                  :
                  (this.props.type == 4 ? 
                    <img className="room-card-img" src={require("../../../assets/img/rooms/8_1.jpg")} />
                    :
                    (this.props.type == 5 ? 
                      <img className="room-card-img" src={require("../../../assets/img/rooms/4_1.jpg")} />
                      :
                      (this.props.type == 6 ? 
                        <img className="room-card-img" src={require("../../../assets/img/rooms/8_1.jpg")} />
                        :
                        (this.props.type == 7 ? 
                          <img className="room-card-img" src={require("../../../assets/img/rooms/12_1.jpg")} />
                          :
                          (this.props.type == 8 ? 
                            <img className="room-card-img" src={require("../../../assets/img/rooms/5_1.jpg")} />
                            :
                            (this.props.type == 9 ? 
                              <img className="room-card-img" src={require("../../../assets/img/rooms/7_1.jpg")} />
                              :
                              (this.props.type == 10 ? 
                                <img className="room-card-img" src={require("../../../assets/img/rooms/9_1.jpg")} />
                                :
                                (this.props.type == 11 ? 
                                  <img className="room-card-img" src={require("../../../assets/img/rooms/10_1.jpg")} />
                                  :
                                  (this.props.type == 12 ? 
                                    <img className="room-card-img" src={require("../../../assets/img/rooms/11_1.jpg")} />
                                    :
                                    <img className="room-card-img" src={require("../../../assets/img/rooms/12_1.jpg")} />
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          }
          <div className="room-card-shadow"></div>
        </div>
        <h3 className="room-card-title">{this._getTitleType(this.props.title, this.props.type)}</h3>
        <p className="room-card-text">{this.props.devices} устройств</p>
      </a>
    );
  }

}
export default UiRoomCard;