import React, { Component } from 'react';
import windowSize from 'react-window-size';

import { Line } from 'react-chartjs-2';

import UiAlert from '../../components/ui/modals/UiAlert.js';
import UiHeader from '../../components/ui/UiHeader.js';
import UiLoader from '../../components/ui/modals/UiLoader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';
import UiNotification from '../../components/ui/cards/UiNotification.js';
import UiBreadcrumb from '../../components/ui/UiBreadcrumb.js';
import UiEventCard from '../../components/ui/cards/UiEventCard.js';
import UiStatisticsCard from '../../components/ui/cards/UiStatisticsCard.js';
import UiVideoCard from '../../components/ui/cards/UiVideoCard.js';

import ENV from '../../services/Env.js';
import { storeData, retrieveData } from '../../services/Storage';
import { loadUser, getUser, getBuildings, getEvents } from '../../services/Users.js';
import { getAllDevices, getScripts, addScript, updateScript, deleteScript } from '../../services/Devices.js';

class Notifications extends Component {

  state = {
    loader: false,
    contentHeight: this.props.windowHeight - 130,
    tabIndex: 0,
    tabIndexSel: 0,

    selectedDate: 1,
    notificationsList: [],
    notificationsListTitle: [],

    data: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Отчеты',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [65, 59, 80, 81, 56, 55, 40]
        }
      ]
    }

  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();
  }

  showAlert(title, type) {
    this.setState({ isAlertShow: true, alertText: title, alertType: type });
  }

  _load() {
    let _user = loadUser();
    this.setState({ loader: true, user: _user });

    getBuildings(_user.id).then((data) => {
      if (data.response) {
        if (data.response.length > 0) {

          global.selectedBuildingId = retrieveData('selectedBuildingId');
          if (!global.selectedBuildingId) global.selectedBuildingId = data.response[0].id;
          getEvents(global.selectedBuildingId, 0).then((res) => {
            console.log(res)
            let _dev = [];
            res.response.data.notifications.map((item) => {
              if(!this._hasInArr(_dev , item.message.dev)) _dev.push(item.message.dev);
            })

            this.setState({ loader: false, notificationsListTitle: _dev, notificationsList: res.response.data.notifications })
          })

        }
      }
    })

  }

  _hasInArr(_arr, _t){
    let f = false;
    _arr.map((item) => {
      if(item == _t) f = true;
    })
    return f;
  }

  _checkDate(timestamp) {
    var d = new Date();
    d.setDate(d.getDate() - this.state.selectedDate);

    return new Date(timestamp) > d;
  }

  _getTime(timestamp) {
    var date = new Date(timestamp);

    return (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + " - "
      + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "." + (date.getMonth() < 10 ? "0" + date.getMonth() : date.getMonth()) + "." + date.getFullYear();
  }

  _getTimeS(timestamp) {
    var date = new Date(timestamp);

    return  (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "." + (date.getMonth() < 10 ? "0" + date.getMonth() : date.getMonth()) + "." + date.getFullYear();
  }

  _createChart(_item) {
    let _arr = [];
    let _arr2 = [];
    let _metr = "";
    this.state.notificationsList.map((item) => {
      if(_arr.length < 100) {
        if(   item.message.dev == _item ){
          if( item.message.l != "on" && item.message.l != "off" ){
            let ss = item.message.l.split(" ");
            _metr = ss[1];
            _arr.push(ss[0]);
            _arr2.push(this._getTimeS(item.timestamp) );
          } else {
            _metr = "Состояние";
            _arr.push(item.message.l == "on" ? 10 : 5);
            _arr2.push(this._getTimeS(item.timestamp) );
          }
        }  
      }
   
    })
    //console.log(_arr, _arr2);
    let dd = this.state.data;
    dd.labels = _arr2;
    dd.datasets[0].lebel = _metr;
    dd.datasets[0].data = _arr;
    this.setState({
      data: dd
    })
  }

  render() {

    var list = this.state.notificationsList.reverse().map((item, index) => {
      if (this._checkDate(item.timestamp)) {
        return (
          <UiNotification key={index} deviceType="text" time={this._getTime(item.timestamp)} title={item.message.dev} text={item.message.l} />
        )
      }
    })

    var deviceTargetLevelSelector = this.state.notificationsListTitle.map((item, index) => {
      return (
        <option value={item} key={index} >{item}</option>
      )
    })

    return (
      <div ref={this.divPage} id="page">
        <UiLoader show={this.state.loader} />

        <div className="wrapper">
          <UiHeader />
          <div className="content">
            <UiLeftMenu type="2" activePage={11} />

            <div className="container">

              <div className="card content-card mt-4">
                <div className="card-body" style={{ minHeight: this.state.contentHeight + 'px' }}>
                  <div className="body-inner">
                    <h2 className="body-title">Уведомления</h2>
                    <UiBreadcrumb backPress="/" linksList={[{ value: 0, linkPress: "/notifications", linkText: "Уведомления" }]} />
                    <div className="body-scroll">

                      <div className="tabs-list">
                        <div className="btn-group btn-group-toggle" data-toggle="buttons">
                          <button className={"btn btn-outline-warning " + (this.state.tabIndexSel == 0 ? 'active' : null)} onClick={() => this.setState({ tabIndexSel: 0 })}>Уведомления</button>
                          <button className={"btn btn-outline-warning " + (this.state.tabIndexSel == 1 ? 'active' : null)} onClick={() => this.setState({ tabIndexSel: 1 })}>Отчеты</button>
                        </div>
                      </div>

                      {this.state.tabIndexSel == 0 ?

                        <div className="links-block">
                          <div className="info-block">
                            <div className="tabs-list">
                              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                <button className={"btn btn-outline-warning " + (this.state.tabIndex == 0 ? 'active' : null)} onClick={() => this.setState({ tabIndex: 0, selectedDate: 1 })}>Сегодня</button>
                                <button className={"btn btn-outline-warning " + (this.state.tabIndex == 1 ? 'active' : null)} onClick={() => this.setState({ tabIndex: 1, selectedDate: 30 })}>За 30 дней</button>
                              </div>
                            </div>
                            <div className="card-list card-list-lines device-cards">
                              {list}
                            </div>
                          </div>
                        </div>
                        :
                        <div className="links-block">
                          <div className="info-block">

                            <select className="form-control" onChange={(e) => this._createChart(e.target.value)  }>
                              {deviceTargetLevelSelector}
                            </select>

                            <div className="card-list card-list-lines device-cards">
                              <Line data={this.state.data} />
                            </div>
                          </div>
                        </div>

                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UiAlert
          show={this.state.isAlertShow}
          callBack={() => this.setState({ isAlertShow: false })}
          title="Внимание!"
          text={this.state.alertText}
          leftBtnText="Ok"
        />
      </div>

    );
  }
}

export default windowSize(Notifications);