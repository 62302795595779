import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { 
  Home, Login, Devices, Device, Events, Notifications, Parent, Profile, Rooms, Room, Scripts, Setting, Video, 
  AllDevices, Houses, Support, Users, NotFound 
} from '../';

class Container extends Component {
  render() {
    return (
      <div>
        <BrowserRouter basename="/">
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/login' component={Login} />

            <Route exact path='/devices' component={Devices} />
            <Route exact path='/device' component={Device} />
            <Route exact path='/events' component={Events} />
            <Route exact path='/notifications' component={Notifications} />
            <Route exact path='/parent' component={Parent} />
            <Route exact path='/profile' component={Profile} />
            <Route exact path='/rooms' component={Rooms} />
            <Route exact path='/room/:id' component={Room} />
            <Route exact path='/scripts' component={Scripts} />
            <Route exact path='/setting' component={Setting} />
            <Route exact path='/video' component={Video} />

            <Route exact path='/alldevices' component={AllDevices} />
            <Route exact path='/houses' component={Houses} />
            <Route exact path='/support' component={Support} />
            <Route exact path='/users' component={Users} />
            
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>

      </div>
    );
  }
}

export default Container;