import React, { Component } from 'react';
import windowSize from 'react-window-size';

import UiAlert from '../../components/ui/modals/UiAlert.js';
import UiHeader from '../../components/ui/UiHeader.js';
import UiLoader from '../../components/ui/modals/UiLoader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';
import UiBreadcrumb from '../../components/ui/UiBreadcrumb.js';
import UiScriptCard from '../../components/ui/cards/UiScriptCard.js';
import UiStatisticsCard from '../../components/ui/cards/UiStatisticsCard.js';

import ENV from '../../services/Env.js';
import { storeData, retrieveData } from '../../services/Storage';
import { loadUser, getUser, getBuildings } from '../../services/Users.js';
import { getAllDevices, getScripts, addScript, updateScript, deleteScript } from '../../services/Devices.js';

class Scripts extends Component {

  state = {
    loader: false,

    selectedConditionTypeId: 0,
    user: {
      adress: "",
      city: {
        title: ""
      }
    },

    selectedTargettitle: "Если -> Тогда",
    selectedDeviceType: "time",
    selectedLevel: "20:00",
    selectedOperator: "<=",

    selectedTargetdDeviceId: "MailNotifier_12",
    selectedTargetDeviceType: "toggleButton",
    selectedTargetLvl: "on",


    rulesScriptList: [],
    rulesScriptDisabledList: [],

    contentHeight: this.props.windowHeight - 130,

    selectedOptions: {
      level: null,
      operator: null,
      min: 0,
      max: 99,
      default: {
        deviceId: '',
        deviceType: 'switchMultilevel',
        level: 'on',
        exact: 0,
        operator: '=',
        sendAction: false,
        reverseLevel: 'off'
      }
    },
    selectedTargetOptions: {
      level: null,
      operator: null,
      min: 0,
      max: 99,
      default: {
        deviceId: '',
        deviceType: 'switchMultilevel',
        level: 'on',
        exact: 0,
        operator: '=',
        sendAction: false,
        reverseLevel: 'off'
      }
    },

    rule: {
      show: true,
      tab: 'if',
      namespaces: [],
      rooms: [],
      options: {
        switchBinary: {
          level: ['on', 'off'],
          default: {
            deviceId: '',
            deviceType: 'switchBinary',
            level: 'on',
            sendAction: false,
            reverseLevel: 'off'
          }
        },
        sensorBinary: {
          level: ['on', 'off'],
          default: {
            deviceId: '',
            deviceType: 'sensorBinary',
            level: 'on',
            sendAction: false,
            reverseLevel: null
          }
        },
        doorlock: {
          level: ['open', 'close'],
          default: {
            deviceId: '',
            deviceType: 'doorlock',
            level: 'open',
            sendAction: false,
            reverseLevel: 'close'
          }
        },
        switchRGBW: {
          level: ['on', 'off', 'colors'],
          min: 0,
          max: 255,
          default: {
            deviceId: '',
            deviceType: 'switchRGBW',
            level: 'on',
            sendAction: false,
            reverseLevel: null
          }
        },
        switchControl: {
          level: ['on', 'off', 'upstart', 'upstop', 'downstart', 'downstop'],
          default: {
            deviceId: '',
            deviceType: 'switchControl',
            level: 'on',
            sendAction: false,
            reverseLevel: null
          }
        },
        sensorDiscrete: {
          //TODO
          //level: ['press', 'hold', 'release', 'tap', 'swipe_up', 'swipe_down', 'swipe_left', 'swipe_right', 'swipe_top_left_to_bottom_right', 'swipe_top_right_to_bottom_left', 'swipe_bottom_left_to_top_right', 'swipe_bottom_right_to_top_left'],
          default: {
            deviceId: '',
            deviceType: 'sensorDiscrete',
            level: '',
            sendAction: false,
            reverseLevel: null
          }
        },
        sensorMultilevel: {
          operator: ['=', '!=', '>', '>=', '<', '<='],
          default: {
            deviceId: '',
            deviceType: 'sensorMultilevel',
            level: 0,
            operator: '=',
            sendAction: false,
            reverseLevel: null
          }
        },
        switchMultilevel: {
          level: ['on', 'off', 'lvl'],
          operator: ['=', '!=', '>', '>=', '<', '<='],
          min: 0,
          max: 99,
          default: {
            deviceId: '',
            deviceType: 'switchMultilevel',
            level: 'on',
            exact: 0,
            operator: '=',
            sendAction: false,
            reverseLevel: 'off'
          }
        },
        thermostat: {
          level: ['on', 'off', 'lvl'],
          operator: ['=', '!=', '>', '>=', '<', '<='],
          min: 0,
          max: 99,
          default: {
            deviceId: '',
            deviceType: 'thermostat',
            level: 'on',
            exact: 0,
            operator: '=',
            sendAction: false,
            reverseLevel: null
          }
        },
        toggleButton: {
          default: {
            deviceId: '',
            deviceType: 'toggleButton',
            level: 'on',
            sendAction: false,
            reverseLevel: null
          }
        },
        time: {
          operator: ['<=', '>='],
          default: {
            type: 'time',
            operator: '>=',
            level: '00:00',
          }
        },
        nested: {
          logicalOperator: ['and', ',or'],
          default: {
            type: 'nested',
            logicalOperator: 'and',
            tests: []

          }
        },
        compare: {
          binaryOperators: ['=', '!='],
          multilevelOperators: ['=', '!=', '>', '>=', '<', '<='],
          default: {
            type: 'compare',
            operator: '=',
            operators: [],
            devices: []
          }
        },
        notification: {
          default: {
            target: '',
            target_custom: '',
            message: ''
          }
        }
      },
      source: {
        deviceTypes: ['toggleButton', 'switchControl', 'switchBinary', 'switchMultilevel', 'sensorBinary', 'sensorMultilevel', 'sensorDiscrete'],
        selected: {
          device: ''
        },
        devicesInRoom: [],
        devices: []
      },
      target: {
        deviceTypes: ['doorlock', 'switchBinary', 'switchMultilevel', 'thermostat', 'switchRGBW', 'switchControl', 'toggleButton', 'notification'],
        devicesInRoom: [],
        availableDevices: [],
        assignedDevices: [],
      },
      else: {
        deviceTypes: ['doorlock', 'switchBinary', 'switchMultilevel'],
      },
      advanced: {
        tab: 'if',
        target: {
          devicesInRoom: [],
          availableDevices: [],
          assignedDevices: [],
          eventSourceDevices: [],
          eventSourceTypes: ['toggleButton', 'notification']
        },
        tests: {
          devicesInRoom: [],
          availableDevices: [],
          assignedDevices: [],
          types: ['switchBinary', 'sensorBinary', 'doorlock', 'switchRGBW', 'switchControl', 'sensorDiscrete', 'sensorMultilevel', 'switchMultilevel', 'thermostat', 'toggleButton', 'time', 'nested']
        }
      }
    }

  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();
  }

  showAlert(title, type) {
    this.setState({ isAlertShow: true, alertText: title, alertType: type });
  }

  _load() {
    let _user = loadUser();
    this.setState({ loader: true, user: _user });

    getBuildings(_user.id).then((data) => {
      if (data.response) {
        if (data.response.length > 0) {

          global.selectedBuildingId = retrieveData('selectedBuildingId');
          if (!global.selectedBuildingId) global.selectedBuildingId = data.response[0].id;
          getScripts(global.selectedBuildingId).then((res) => {
            let arr = [];
            let arr2 = [];
            if (res.response) {
              res.response.data.map((item) => {
                if (item.moduleId == "Rules" && item.active) arr.push(item);
                if (item.moduleId == "Rules" && !item.active) arr2.push(item);
              })
            }
            this.setState({ rulesScriptList: arr, rulesScriptDisabledList: arr2 });
          })
          getAllDevices(global.selectedBuildingId).then((res) => {
            if (res.success) {
              if (res.response) {
                if (res.response.data.devices) {
                  let rule = this.state.rule;
                  var whiteListSource = rule.source.deviceTypes;
                  var whiteListTarget = rule.target.deviceTypes;
                  var whiteListAdvancedTests = rule.advanced.tests.types;
                  var whiteListAdvancedEventSource = rule.advanced.target.eventSourceTypes;
                  var devices = res.response.data.devices.map(function (v) {
                    var obj = {
                      deviceId: v.id,
                      deviceName: v.metrics.title,
                      deviceType: v.deviceType,
                      probeType: v.probeType,
                      level: !isNaN(v.metrics.level) ? parseInt(v.metrics.level) : v.metrics.level,
                      //level:v.metrics.level,
                      location: v.location,
                      iconPath: v.iconPath
                    };
                    return obj;
                  });
                  // Set source devices
                  rule.source.devices = devices.filter(function (v) {
                    return whiteListSource.indexOf(v.deviceType) > -1;
                  })
                  // Set target devices
                  rule.target.availableDevices = devices.filter(function (v) {
                    // Replacing deviceType with "notification"
                    if (v.probeType == 'notification_push') {
                      v.deviceType = 'notification';
                    }
                    return whiteListTarget.indexOf(v.deviceType) > -1;
                  })
                  this.setState({ devicesList: res.response.data.devices, rule: rule, loader: false });
                } else {
                  this.showAlert("Вниманание ошибка соединения", 0);
                }
              } else {
                this.setState({ loader: false });
              }
            }
          })
        }
      }
    })


  }

  _selectTypeByDeviceId(id) {
    let val = null;
    this.state.rule.source.devices.map((item, index) => {
      if (item.deviceId == id) val = item;
    });
    console.log(this.state.rule.options[val.deviceType]);
    this.setState({
      selectedDeviceType: val.deviceType,
      selectedOptions: this.state.rule.options[val.deviceType],
    });
  }

  _selectTargetTypeByDeviceId(id) {
    let val = null;
    this.state.rule.target.availableDevices.map((item, index) => {
      if (item.deviceId == id) val = item;
    });
    console.log(val, id);
    this.setState({
      selectedTargetDeviceType: val.deviceType,
      selectedTargetOptions: this.state.rule.options[val.deviceType],
    });
  }

  _localeTranslate(str) {
    if (str == "Send Email Notification") {
      return "Отправить Email";
    } else if (str == "on") {
      return "Вкл";
    } else if (str == "off") {
      return "Выкл";
    } else if (str == "lvl") {
      return "Уровень";
    } else if (str == "colors") {
      return "Цвета";
    } else {
      return str;
    }
  }

  _add() {
    let json = {
      uid: this.state.user.id,
      id: 0,
      moduleId: "Rules",
      active: true,
      title: "",
      params: {
        simple: {
          triggerEvent: {},
          triggerDelay: 0,
          targetElements: [],
          sendNotifications: [],
          reverseDelay: 0
        },
        advanced: {
          active: false,
          triggerScenes: [],
          triggerDelay: 0,
          logicalOperator: "and",
          tests: [],
          targetElements: [],
          sendNotifications: [],
          reverseDelay: 0,
          triggerOnDevicesChange: true
        },
        reverse: false
      }
    };

    json.title = this.state.selectedTargettitle;
    json.params.simple.triggerEvent = {
      deviceId: this.state.selectedDeviceId,
      type: this.state.selectedDeviceType,
      level: this.state.selectedLevel,
      operator: this.state.selectedOperator,
      sendAction: false,
    };

    json.params.simple.targetElements.push({
      deviceId: this.state.selectedTargetdDeviceId,
      deviceType: this.state.selectedTargetDeviceType,
      level: this.state.selectedTargetLvl,
      sendAction: false,
    });

    this.setState({ loader: true });
    addScript(json).then((res) => {
      this.setState({ loader: false });
      if (res.response.code == 201) {
        this._load();
      } else {
        this.showAlert("Ошибка добавления", 0);
      }
      console.log(res)
    })
  }

  _activScript(item, _status) {
    let json = item;
    json.uid = this.state.user.id;
    json.active = _status;
    this.setState({ loader: true });
    updateScript(json).then((res) => {
      this.setState({ loader: false });
      console.log(json, res)
      if (res.response) {
        this._load();
      } else {
        this.showAlert("Ошибка редактирования", 0);
      }
      console.log(res)
    })
  }

  _deleteScript(item) {
    let json = item;
    json.uid = this.state.user.id;
    this.setState({ loader: true });
    deleteScript(json).then((res) => {
      this.setState({ loader: false });
      console.log(json, res)
     // if (res.response) {
        this._load();
     // } else {
     //   this.showAlert("Ошибка удаления", 0);
     // }
      console.log(res)
    })
  }


  render() {
    var rulesList = this.state.rulesScriptList.map((item, index) => {
      return (
        <UiScriptCard
          key={index}
          scriptType="0"
          title={item.title}
          info={item.params.simple}
          active={false}
          activPress={() => this._activScript(item, false)}
          deletePress={() => this._deleteScript(item)}
        />
      )
    })

    var rulesDisabledList = this.state.rulesScriptDisabledList.map((item, index) => {
      return (
        <UiScriptCard
          key={index}
          scriptType="0"
          title={item.title}
          info={item.params.simple}
          active={true}
          activPress={() => this._activScript(item, true)}
          deletePress={() => this._deleteScript(item)}
        />
      )
    })


    var timeOperatorSelector = this.state.rule.options.time.operator.map((item, index) => {
      return (
        <option value={item} key={index} >{item}</option>
      )
    })
    var deviceSelector = this.state.rule.source.devices.map((item, index) => {
      return (
        <option value={item.deviceId} key={index} >{this._localeTranslate(item.deviceName)}</option>
      )
    })
    var deviceTarget = this.state.rule.target.availableDevices.map((item, index) => {
      if (item.deviceName != "Scene") {
        return (
          <option value={item.deviceId} key={index} >{this._localeTranslate(item.deviceName)}</option>
        )
      }

    })
    if (this.state.selectedOptions.operator) {
      var deviceOperatorSelector = this.state.selectedOptions.operator.map((item, index) => {
        return (
          <option value={item} key={index} >{item}</option>
        )
      })
    }
    if (this.state.selectedOptions.level) {
      var deviceLevelSelector = this.state.selectedOptions.level.map((item, index) => {
        return (
          <option value={item} key={index} >{this._localeTranslate(item)}</option>
        )
      })
    }
    if (this.state.selectedTargetOptions.level) {
      var deviceTargetLevelSelector = this.state.selectedTargetOptions.level.map((item, index) => {
        return (
          <option value={item} key={index} >{this._localeTranslate(item)}</option>
        )
      })
    }


    return (
      <div ref={this.divPage} id="page">
        <UiLoader show={this.state.loader} />


        <div className="wrapper">
          <UiHeader />
          <div className="content">
            <UiLeftMenu type={this.state.user.type} fio={this.state.user.fio} activePage={1} />

            <div className="container">

              <div className="card content-card mt-4">
                <div className="card-body" style={{ minHeight: this.state.contentHeight + 'px' }}>
                  <div className="body-inner">
                    <h2 className="body-title">Сценарии <span>/ {this.state.user.adress}</span></h2>
                    <UiBreadcrumb backPress="/" linksList={[{ value: 0, linkPress: "/scripts", linkText: "Сценарии" }]} />
                    <div className="body-scroll">

                      <div className="card card-gray">
                        <div className="row">
                          <div className="col-6">
                            <UiStatisticsCard title={this.state.rulesScriptList.length} text="Сценариев активны" />
                          </div>
                          <div className="col-6">
                            <UiStatisticsCard title={this.state.rulesScriptDisabledList.length} text="Сценарий приостановлен" />
                          </div>
                        </div>
                      </div>



                      <div className="links-block">
                        <div className="info-block">
                          <div className="form form-no-indent">
                            <h2 className="form-title">Создать сценарий</h2>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <input type="text" className="form-control" placeholder="Название сценария" defaultValue={"Если -> Тогда"} onChange={(e) => this.setState({ selectedTargettitle: e.target.value })} />
                                </div>
                              </div>
                            </div>

                            <h3 className="form-title">ЕСЛИ ВЫПОЛНЯЕТЬСЯ</h3>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <select className="form-control" onChange={(e) => {
                                    if (e.target.value != 0) {
                                      this.setState({
                                        selectedConditionTypeId: e.target.value,
                                        selectedLevel: "on",
                                        selectedOperator: "=",
                                      })
                                    } else {
                                      this.setState({
                                        selectedConditionTypeId: e.target.value,
                                        selectedDeviceType: "time",
                                        selectedOperator: undefined,
                                        selectedDeviceId: undefined,
                                      })
                                    }

                                  }
                                  }>
                                    <option value="0"  >Время</option>
                                    <option value="1"  >Усройство</option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  {this.state.selectedConditionTypeId == 0 ?
                                    <select className="form-control" onChange={(e) => this.setState({ selectedOperator: e.target.value })}>
                                      {timeOperatorSelector}
                                    </select>
                                    : null}

                                  {this.state.selectedConditionTypeId == 1 ?
                                    <select className="form-control" onChange={(e) => {
                                      this.setState({ selectedDeviceId: e.target.value });
                                      this._selectTypeByDeviceId(e.target.value);
                                    }}>
                                      {deviceSelector}
                                    </select>
                                    : null}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  {this.state.selectedConditionTypeId == 0 ? <input type="time" defaultValue={"20:00"} className="form-control" onChange={(e) => this.setState({ selectedLevel: e.target.value })} /> : null}
                                  {this.state.selectedConditionTypeId == 1 && this.state.selectedOptions.operator ?
                                    <select className="form-control" onChange={(e) => this.setState({ selectedOperator: e.target.value })}>
                                      {deviceOperatorSelector}
                                    </select>
                                    : null}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  {this.state.selectedConditionTypeId == 1 && this.state.selectedOptions.level ?
                                    <select className="form-control" onChange={(e) => this.setState({ selectedLevel: e.target.value })}>
                                      {deviceLevelSelector}
                                    </select>
                                    : null}
                                </div>
                              </div>
                            </div>


                            <h3 className="form-title">ТО ВКЛЮЧИТЬ УСТРОЙСТВО</h3>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <select className="form-control" onChange={(e) => {
                                    this.setState({ selectedTargetdDeviceId: e.target.value });
                                    this._selectTargetTypeByDeviceId(e.target.value);
                                  }}>
                                    {deviceTarget}
                                  </select>
                                </div>
                                <div className="form-group">
                            
                                  <button type="button" className="btn btn-outline-warning btn-block" onClick={() => this._add()}>Создать</button>

                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  {this.state.selectedTargetOptions.level ?
                                    <select className="form-control" onChange={(e) => this.setState({ selectedTargetOperator: e.target.value })}>
                                      {deviceTargetLevelSelector}
                                    </select>
                                    : null}
                                </div>
                                <div className="form-group">
                                  {this.state.selectedTargetOperator == "lvl" ?
                                    <input type="number" className="form-control" placeholder="уровень" defaultValue={0} onChange={(e) => this.setState({ selectedTargetLvl: e.target.value })} />
                                    : null}
                                </div>
                              </div>
                            </div>


                          </div>

                          <div className="title-wrap link-title-wrap">
                            {rulesList.length > 0 ? <h3 className="info-block-title">Активные сценарии</h3> : null}
                          </div>
                          <div className="card-list card-list-lines">
                            {rulesList}
                          </div>
                        </div>

                        <div className="info-block">
                          <div className="title-wrap link-title-wrap">
                            {rulesDisabledList.length > 0 ? <h3 className="info-block-title">Приостановленные сценарии</h3> : null}
                          </div>
                          <div className="card-list card-list-lines">
                            {rulesDisabledList}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UiAlert
          show={this.state.isAlertShow}
          callBack={() => this.setState({ isAlertShow: false })}
          title="Внимание!"
          text={this.state.alertText}
          leftBtnText="Ok"
        />
      </div>

    );
  }
}

export default windowSize(Scripts);