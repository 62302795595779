import React, { Component } from 'react';
class UiScriptSmallCard extends Component {
  state = {}
  constructor(props) {
    super(props);
  }
  componentDidUpdate(prevProps, prevState) { }
  render() {
    return (
      <div className={"card script-small-card" + (this.props.active ? " active" : "")} onClick={()=> this.props.onClick() }>
        {this.props.deviceType == "plug" ?
          (this.props.active ? 
            <img className="script-small-card-icon" src={require("../../../assets/img/scripts/sensor-white.svg")} /> :
            <img className="script-small-card-icon" src={require("../../../assets/img/scripts/sensor.svg")} />
          )
          : null
        }
        <h3 className="script-small-card-title">{this.props.title}</h3>
      </div>
    );
  }
}
export default UiScriptSmallCard;