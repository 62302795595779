import React, { Component } from 'react';

class UiEditChild extends Component {

    state = {
        selectedId: null,
    }

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.show && this.state.selectedId == null ){
            let _arr = [];
            this.props.devicesList.map((item, index) => {
                if (item.probeType != '' && !this.checkHasDevice(item.id)) {
                    _arr.push(item);
                }
            })

            if(_arr.length > 0) this.setState({selectedId: _arr[0].id });
        }
    }

    checkHasDevice(_item) {
        let val = false;
        this.props.devicesChildList.map((item) => {
            if (item.device_id == _item) val = true;
        })
        return val;
    }

    render() {
        var devices = this.props.devicesList.map((item, index) => {
            if (item.probeType != '' && !this.checkHasDevice(item.id)) {
                return (
                    <option value={item.id} key={index} >{item.id}</option>
                )
            }

        })

        var tablesList = this.props.devicesChildList.map((item, index) => {
            return (
                <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{item.device_id}</td>
                    <td>
                        <button type="button" className="btn btn-sm btn-outline-warning btn-block" onClick={() => this.props.removeDevice(item.id)}>Удалить</button>
                    </td>
                </tr>
            )
        })

        return (
            <div className={"alert-wrap " + (this.props.show ? "show" : "hide")}>
                <div className="card modal-card">
                    <div className="modal-card-inner">

                        <h3 className="form-title">Доступные устройства для {this.props.userTitle}</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <select className="form-control" onChange={(e) => this.setState({ selectedId: e.target.value })}>
                                        {devices}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <button type="button" className="btn btn-outline-warning btn-block" onClick={() => {
                                        if(this.state.selectedId != null ){
                                            this.props.addDevice(this.state.selectedId);
                                            this.setState({ selectedId: null })
                                        }
                                        
                                    }}>Добавить</button>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                {tablesList.length > 0 ?
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Название</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tablesList}
                                        </tbody>
                                    </table>

                                    : null}

                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6">

                        </div>
                        <div className="col-md-6">
                            <button type="button" className="btn btn-outline-info btn-block" onClick={() => this.props.cancelPress()} >Закрыть</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UiEditChild;