class Source {

	constructor( dom ) {

		this.dom = dom;

	}

	setText( text ) {

		this.dom.textContent = text;

	}

}

export default Source;