import React, { Component } from 'react';

class UiLoader extends Component {

    state = {}

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) { }

    render() {

        return (
            <div className={"loader " + (this.props.show ? "show" : "hide")}>
                <img className="loader-gif" src={require("../../../assets/img/loader.gif")} />
            </div>
        );
    }
}

export default UiLoader;