import React, { Component } from 'react';
class UiScriptCard extends Component {

  state = {
    title: ""
  }

  constructor(props) {
    super(props);


  }

  _localeTranslate(str) {
    if (str == "Send Email Notification") {
      return "Отправить Email";
    } else  if (str == "time") {
      return "Время";
    } else  if (str == "on") {
      return "Вкл";
    } else if (str == "off") {
      return "Выкл";
    } else if (str == "lvl") {
      return "Уровень";
    } else if (str == "colors") {
      return "Цвета";
    } else {
      return str;
    }
  }

  componentDidMount() {
    
  }

  render() {
    return (
      <div className="card script-card">
        <div className="script-card-info">
          <div className="top-info">
            {this.props.scriptType == 0 ?
              <div className="script-card-img-wrap">
                <img className="script-card-img" src={require("../../../assets/img/scripts/sensor.svg")} />
                <img className="script-card-img hover" src={require("../../../assets/img/scripts/sensor-white.svg")} />
              </div>
              :
              (this.props.scriptType == 1 ?
                <div className="script-card-img-wrap">
                  <img className="script-card-img" src={require("../../../assets/img/scripts/temperature.svg")} />
                  <img className="script-card-img hover" src={require("../../../assets/img/scripts/temperature-white.svg")} />
                </div>
                :
                null
              )
            }
            <h3 className="script-card-title">{this.props.title}</h3>
          </div>
          <div className="bottom-info">
            <p className="script-info-text">{this.state.title}</p>
          </div>
        </div>
        <div className="script-card-controls">
          {/* Кнопка с иконкой плей */}
          {this.props.active ?
            <button className="btn script-card-btn" onClick={()=> this.props.activPress() }>
              <img className="script-card-btn-icon" src={require("../../../assets/img/ui-icons/play-button.svg")} />
              <img className="script-card-btn-icon hover" src={require("../../../assets/img/ui-icons/play-button-white.svg")} />
            </button>
            :
            <button className="btn script-card-btn"  onClick={()=> this.props.activPress() }>
              <img className="script-card-btn-icon" src={require("../../../assets/img/ui-icons/stop.svg")} />
              <img className="script-card-btn-icon hover" src={require("../../../assets/img/ui-icons/stop-white.svg")} />
            </button>
          }

          {/* Кнопка с иконкой стоп
          <button className="btn script-card-btn">
            <img className="script-card-btn-icon" src={require("../../../assets/img/ui-icons/stop.svg")} />
            <img className="script-card-btn-icon hover" src={require("../../../assets/img/ui-icons/stop-white.svg")} />
          </button> */}
          <button className="btn script-card-btn" onClick={()=> this.props.deletePress() }>
            <img className="script-card-btn-icon" src={require("../../../assets/img/ui-icons/trash.svg")} />
            <img className="script-card-btn-icon hover" src={require("../../../assets/img/ui-icons/trash-white.svg")} />
          </button>
        </div>
      </div>
    );
  }
}
export default UiScriptCard;