import React, { Component } from 'react';
class UiBreadcrumb extends Component {
  state = {}
  constructor(props) {
    super(props);
  }
  componentDidUpdate(prevProps, prevState) { }
  render() {
    var Links = this.props.linksList.map((item,index)=>{
      return (
        <li key={index} className={"breadcrumb-item " + (item.value == (item.lenght) ? "active" : "")}>
          <a href={item.linkPress}>{item.linkText}</a>
        </li>
      );
    });

    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item-back">
            <a href={this.props.backPress}><img className="breadcrumb-icon" src={require("../../assets/img/ui-icons/arrow.svg")} /></a>
          </li>
          <li className="breadcrumb-item"><a href="/">Главная</a></li>
          {Links}
        </ol>
      </nav>
    );
  }
}
export default UiBreadcrumb;