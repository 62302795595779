import React, { Component } from 'react';
import windowSize from 'react-window-size';

import UiBreadcrumb from '../../components/ui/UiBreadcrumb.js';
import UiHeader from '../../components/ui/UiHeader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';
import UiDeviceCard from '../../components/ui/cards/UiDeviceCard.js';
import UiDeviceGroupCard from '../../components/ui/cards/UiDeviceGroupCard.js';
import UiStatisticsCard from '../../components/ui/cards/UiStatisticsCard.js';
import UiLoader from '../../components/ui/modals/UiLoader.js';

import ENV from '../../services/Env.js'
import { loadUser, getUser, getBuildings } from '../../services/Users.js';
import { storeData, retrieveData } from '../../services/Storage';
import { getAllDevices, getAllGroups } from '../../services/Devices.js';
import { getAllRooms } from '../../services/Rooms.js';


class Devices extends Component {

  state = {
    devicesList: [],
    user: {
      adress: "",
      city: {
        title: ""
      }
    },

    contentHeight: this.props.windowHeight - 130,
    loader: false,

    groupsDevices: [],

  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();
  }

  _load() {
    let _user = loadUser();
    global.selectedBuildingId = retrieveData('selectedBuildingId');
    this.setState({ loader: true, devicesList: [], user: _user, selectedBuildingId: retrieveData('selectedBuildingId') }, () => {

      getAllRooms(_user.id).then((res) => {
        console.log(res)
        this.setState({ roomsList: res.response });
        getBuildings(_user.id).then((data) => {
          if (data.response) {
            if (data.response.length > 0) {
              console.log("res", data.response)
              data.response.map((item) => {
                getAllDevices(item.id).then((res) => {
                  if (res.response) {
                    console.log("getAllDevices", res.response.data.devices)
                    let _devices = res.response.data.devices;
                    _devices.map((item2) => {
                      item2.buildingTitle = item.title;
                      item2.buildingId = item.id;
                    });
                    if (res.success) {
                      let _arr = this.state.devicesList;
                      _arr = _arr.concat(_devices);
                      this.setState({ devicesList: _arr, loader: false });
                    }
                  } else {
                    this.setState({ loader: false });
                  }
                })
              })
            }
          }
        });
        getAllGroups(_user.id).then((groupRes) => {
          console.log(groupRes)
          this.setState({ groupsDevices: groupRes.response })
        })
      });

    });

  }

  _getTitleType(_title, type) {
    let title = "";
    if (title != null) {
      if (type == 0) title = "Гостиная";
      if (type == 1) title = "Кухня";
      if (type == 2) title = "Спальня";
      if (type == 3) title = "Детская";
      if (type == 4) title = "Коридор";
      if (type == 5) title = "Ванная";
      if (type == 6) title = "Подсобка";
      if (type == 7) title = "Свободная";
      if (type == 8) title = "Кабинет";
      if (type == 9) title = "Столовая";
      if (type == 10) title = "Балкон/лоджия";
      if (type == 11) title = "Сад";
      if (type == 12) title = "Офис";
    } else {
      title = _title;
    }
    return title;
  }

  _getRoomByDevice(_device_id) {
    let title = null;
    this.state.roomsList.map((item) => {
      item.devices.map((item2) => {
        if (item2.device_id == _device_id) title = this._getTitleType(item.title, item.type);
      })
    })
    return title;
  }

  _getTurnedDevices() {
    let count = 0;
    this.state.devicesList.map((item) => {
      if (parseInt(item.metrics.level) > 0 || item.metrics.level == "on") count++;
    });
    return count;
  }
  _getTurnedOffDevices() {
    let count = 0;
    this.state.devicesList.map((item) => {
      if (parseInt(item.metrics.level) == 0 || item.metrics.level == "off") count++;
    });
    return count;
  }

  _checkGroupStatus(_devices) {
    let _f = false;
    _devices.map((item2) => {
      this.state.devicesList.map((item) => {
        if (item2.device_id == item.id) {
          if (parseInt(item.metrics.level) > 0 || item.metrics.level == "on") _f = true;
        }
      })
    })
    return _f;
  }

  _getGroupMetrics(_devices) {
    let _arr = [];
    _devices.map((item2) => {
      this.state.devicesList.map((item) => {
        if (item2.device_id == item.id) {
          _arr.push(item);
        }
      })
    })

    return _arr;
  }

  _getDeviceType(_id) {
    let _f = "";
    this.state.devicesList.map((item) => {
      if (_id == item.id) {
        _f = item.deviceType;
      }
    })
    return _f;
  }

  _addToFavorite(_item) {
    alert("Вы успешно добавили устройство в избранное");

    let _arr = retrieveData('favoriteArr');
    if (_arr) {
      let _f = false;
      _arr.map((item) => {
        if (item == _item) _f = true;
      });
      if (!_f) {
        _arr.push(_item);
        storeData('favoriteArr', _arr);
      }
    } else {
      _arr = [];
      _arr.push(_item);
      storeData('favoriteArr', _arr);
    }

  }

  render() {
    var devices = this.state.groupsDevices.map((item, index) => {
      if(item.devices.length > 0 ){
        return (
          <UiDeviceGroupCard
            key={index}
            deviceType={this._getDeviceType(item.devices.length > 0 ? item.devices[0].device_id : null)}
            title={item.title}
            params={this._getGroupMetrics(item.devices)}
            room={this._getRoomByDevice(item.id)}
            status={this._checkGroupStatus(item.devices)}
            update={() => this._load()}
            showLoader={() => this.setState({ loader: true })}
            hasFavorite
            onPress={(e) => {
              e.preventDefault();
              this._addToFavorite(item);
            }}
            onPressOpen={(e) => {
              e.preventDefault();
              console.log(item.probeType)
              if (item.probeType != '') {
                global.selectedBuildingId = item.devices[0].buildingId;
                console.log("global.selectedBuildingId", item.devices[0] )
                storeData("selectedDevice", this._getGroupMetrics(item.devices));
                storeData("selectedDeviceItem", item);
                this.props.history.push("/device")
              }
            }}
          />
        )
      }

    });

    return (
      <div ref={this.divPage} id="page">
        <div className="wrapper">
          <UiHeader />
          <div className="content">
            <UiLeftMenu type={this.state.user.type} fio={this.state.user.fio} activePage={4} />

            <div className="container">

              <div className="card content-card mt-4">
                <div className="card-body" style={{ minHeight: this.state.contentHeight + 'px' }}>
                  <div className="body-inner">
                    <h2 className="body-title">Все Устройства <span>/ </span></h2>
                    <UiBreadcrumb backPress="/" linksList={[{ value: 0, linkPress: "/devices", linkText: "Все устройства" }]} />

                    <div className="body-scroll">

                      <div className="card card-gray">
                        <div className="row">
                          <div className="col-sm-6 col-md-3">
                            <UiStatisticsCard title={this.state.devicesList.length} text="Всего" />
                          </div>
                          <div className="col-sm-6 col-md-3">
                            <UiStatisticsCard title={this._getTurnedDevices()} text="Работают" leftIcon={require("../../assets/img/device/play-button.svg")} />
                          </div>
                          <div className="col-sm-6 col-md-3">
                            <UiStatisticsCard title={this.state.devicesList.length - this._getTurnedOffDevices()} text="Offline" leftIcon={require("../../assets/img/device/pause-button.svg")} />
                          </div>
                          <div className="col-sm-6 col-md-3">
                            <UiStatisticsCard title={this._getTurnedOffDevices()} text="Выключены" leftIcon={require("../../assets/img/device/cancel-button.svg")} />
                          </div>
                        </div>
                      </div>

                      <div className="links-block">
                        <div className="info-block">
                          <div className="title-wrap link-title-wrap">
                            <h3 className="info-block-title">Список устройств</h3>
                          </div>
                          <div className="card-list card-list-lines device-cards">
                            {devices}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <UiLoader show={this.state.loader} />
      </div>

    );
  }
}

export default windowSize(Devices);