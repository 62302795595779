import React, { Component } from 'react';

class UiHeader extends Component {

    state = {}

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) { }

    render() {

        return (
            <header className="header d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-8">
                            <div className="media">
                                <div className="logo-wrap mr-3 pb-2">
                                    <img src={require("../../assets/img/logo-white.png")} alt="Smart Dom" />
                                </div>
                                <div className="media-body align-self-center">
                                    <h1>Smart Dom</h1>
                                    <h5>Панель администрирования</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="logo-text">
                                <img src={require("../../assets/img/logo-text.png")} alt="Smart Dom" />
                            </div>
                        </div>
                    </div>

                </div>
            </header>
        );
    }
}

export default UiHeader;