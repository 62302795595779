import React, { Component } from 'react';

import UiHeader from '../../components/ui/UiHeader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';
import UiRoomCard from '../../components/ui/cards/UiRoomCard.js';
import UiBreadcrumb from '../../components/ui/UiBreadcrumb.js';

import ENV from '../../services/Env.js'

import Editor from '../../components/class/editor/Editor.js';
import Selector from '../../components/class/editor/Selector.js';
import Source from '../../components/class/editor/Source.js';

import { getAllDevices } from '../../services/Devices.js';
import { loadUser, getUser } from '../../services/Users.js';
import { getAllRooms, addRoom, getAllPlans, savePlan } from '../../services/Rooms.js';

const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);


class Rooms extends Component {

  state = {
    isEditorBarOpen: false,
    isAccauntOpen: false,
    isInfoOpen: false,
    isAlertShow: false,
    isAddCart: false,
    loader: false,

    filesList: [],
    selectedFile: null,
    selectedFileName: "",
    selectedFileTypeTitle: "Выберите тип файла",
    selectedFileType: 0,

    roomsList: [],
    userPlan: null,

    size: 10,
    type: 0,
    title: "",

    user: {
      adress: "",
      city: {
        title: ""
      }
    },

    isSelected: false,
    selectedHeight: 0,
    selectedWidth: 0,

    fullScreenMode: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let _user = loadUser();
    this.setState({ user: _user }, () => this._load())

    this.editor = new Editor(document.getElementById("stage"));
    this.editor.setSource(new Source(document.getElementById("textarea")));
    this.selector = new Selector(document.getElementById("stage"), document.getElementsByClassName("contentStage")[0], (e) => {
      if (e) {
        this.setState({ selectedWidth: e.getAttribute("width"), selectedHeight: e.getAttribute("height"), isSelected: true });
      } else {
        this.setState({ isSelected: false });
      }
    });
  }

  _load() {
    getAllRooms(this.state.user.id).then((res) => {
      console.log(res)
      this.setState({ roomsList: res.response })
    })
    getAllPlans(this.state.user.id).then((res) => {
      console.log(res)
      if (res.response.length > 0) {
        this.setState({ userPlan: res.response[0].svg });
        this.editor.setSVG(new DOMParser().parseFromString(res.response[0].svg, 'image/svg+xml'));
      }

    })
  }

  showAlert(title, type) {
    this.setState({ isAlertShow: true, alertText: title, alertType: type });
  }

  _addRoom() {
    if (this.state.size.toFixed) {
      addRoom(this.state.user.id, this.state.title, this.state.size, this.state.type).then((res) => {
        console.log(res)
        this._load();
      })
    } else {
      this.showAlert("Ошибка", 0);
    }

  }

  _addEditorIcon(_type) {
    this.editor.addIcon(_type);
  }

  _addEditorPrimitive(_type) {
    this.editor.addPrimitive(_type);
  }

  _removeEditorElement() {
    this.selector.remove();
  }

  _saveEditor() {
    let _data = this.editor.toString();
    savePlan(this.state.user.id, _data).then((res) => {
      console.log(res)
      this.showAlert("Ваш план сохранен", 0);
      this._load();
    })
  }


  render() {
    var rooms = this.state.roomsList.map((item, index) => {
      return (
        <UiRoomCard key={index} roomId={item.id} roomType={item.type} type={item.type} title={item.title} devices={item.devices.length} />
      )
    })

    return (
      <div ref={this.divPage} id="page">
        <div className="wrapper">
          <UiHeader />
          <div className="content">
            <UiLeftMenu type={this.state.user.type} fio={this.state.user.fio} activePage={3} />

            <div className="container">

              <div className="card content-card mt-4">
                <div className="card-body">

                  <div className="body-inner">
                    <h2 className="body-title">Планировка <span>/ {this.state.user.adress}</span></h2>
                    <UiBreadcrumb backPress="/" linksList={[{ value: 0, linkPress: "/rooms", linkText: "Планировка дома" }]} />
                    <div className="body-scroll">

                      <div className="card card-gray form">
                        <h3 className="form-title">Добавить комнату</h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Название комнаты" onChange={(e) => this.setState({ title: e.target.value })} />
                            </div>
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Площадь" onChange={(e) => this.setState({ size: e.target.value })} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="form-control" onChange={(e) => this.setState({ type: e.target.value })}>
                                <option value="0" >Гостинная</option>
                                <option value="1" >Кухня</option>
                                <option value="2" >Спальня</option>
                                <option value="3" >Детская</option>
                                <option value="4" >Коридор</option>
                                <option value="5" >Ванная</option>
                                <option value="6" >Подсобка</option>
                                <option value="7" >Свободная</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <button type="button" className="btn btn-outline-warning btn-block" onClick={() => this._addRoom()}>Добавить</button>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p>*На плане отображаются только добавленные устройства.</p>
                          </div>
                        </div>
                      </div>

                      {/* + class "open" для открытия на весь экран, для закрытия - убрать class "open" */}
                      <div className={"rooms-plan-wrap" + (this.state.fullScreenMode ? " open" : "")}>
                        <div className="card rooms-plan">

                          <div className="row">
                            <div className="col-md-12">
                              <div className="layout-control">
                                <button type="button" className="btn btn-sm btn-warning mr-1" onClick={() => this.setState({fullScreenMode: true})}>На весь экран</button>
                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.setState({fullScreenMode: false})}>В окне</button>
                              </div>
                              <div className="contentStage">
                                <svg id="stage" width="600" height="600" viewBox="0 0 600 600"></svg>
                                <textarea id="textarea" spellCheck="false" style={{ display: 'none' }} ></textarea>
                              </div>
                            </div>
                          </div>

                          {/* + class "hide" для сворачивания, для открытия - убрать class "hide" */}
                          <div className={"control-panel " + (!this.state.isEditorBarOpen ? 'hide' : '')}>
                            <div className="control-panel-hide">
                              <button className="btn btn-warning btn-sm" onClick={() => this.setState({ isEditorBarOpen: !this.state.isEditorBarOpen })}>
                                <img className="hide-btn" src={require("../../assets/img/ui-icons/settings.svg")} />
                                {this.state.isEditorBarOpen ?
                                  <span>Закрыть</span>
                                  :
                                  <span>Добавить</span>
                                }
                              </button>
                            </div>

                            <div className="panel-buttons">
                              {this.state.isSelected ?
                                <div>
                                  <h3 className="control-panel-title">Настройки:</h3>
                                  <div className="mr-1">H: {this.state.selectedHeight}  W: {this.state.selectedWidth}</div>
                                  <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this.selector.changeSize("height", +10)}>В+</button>
                                  <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this.selector.changeSize("height", -10)}>В-</button>

                                  <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this.selector.changeSize("width", +10)}>Ш+</button>
                                  <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this.selector.changeSize("width", -10)}>Ш-</button>
                                  <hr />
                                </div>
                                : null
                              }

                              <h3 className="control-panel-title">Добавить:</h3>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorPrimitive("room")}>Комната</button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorPrimitive("window")}>Окно #1 верт</button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorPrimitive("window2")}>Окно #2 верт</button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorPrimitive("window3")}>Окно #1 гориз</button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorPrimitive("window4")}>Окно #2 гориз</button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorIcon("door")}>Дверь слева</button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorIcon("door2")}>Дверь снизу</button>

                              <hr />
                              <h3 className="control-panel-title">Добавленные устройства:</h3>

                              <hr />
                              <h3 className="control-panel-title">Новые устройства:</h3>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorIcon("light")}>
                                <img className="device-btn" src={require("../../assets/img/device/light-white.svg")} />
                                <span>Лампа</span>
                              </button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorIcon("lock")}>
                                <img className="device-btn" src={require("../../assets/img/device/password-white.svg")} />
                                <span>Замок</span>
                              </button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorIcon("switch")}>
                                <img className="device-btn" src={require("../../assets/img/device/switch-white.svg")} />
                                <span>Переключатель</span>
                              </button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorIcon("camera")}>
                                <img className="device-btn" src={require("../../assets/img/device/cctv-white.svg")} />
                                <span>Камера</span>
                              </button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorIcon("temperature")}>
                                <img className="device-btn" src={require("../../assets/img/device/temperature-white.svg")} />
                                <span>Термометр</span>
                              </button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorIcon("air")}>
                                <img className="device-btn" src={require("../../assets/img/device/air-white.svg")} />
                                <span>Вентиляция</span>
                              </button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorIcon("heating")}>
                                <img className="device-btn" src={require("../../assets/img/device/heating-white.svg")} />
                                <span>Обогреватель</span>
                              </button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._addEditorIcon("sensor")}>
                                <img className="device-btn" src={require("../../assets/img/device/sensor-white.svg")} />
                                <span>Датчик</span>
                              </button>

                              <hr />
                              <h3 className="control-panel-title">Управление:</h3>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._removeEditorElement()}>
                                <img className="device-btn" src={require("../../assets/img/ui-icons/trash-white.svg")} />
                                <span>Удалить выбранное</span>
                              </button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this.editor.clear()}>
                                <img className="device-btn" src={require("../../assets/img/ui-icons/close-white.svg")} />
                                <span>Очистить все</span>
                              </button>
                              <button type="button" className="btn btn-secondary btn-sm mr-1 mb-1" onClick={() => this._saveEditor()}>
                                <img className="device-btn" src={require("../../assets/img/ui-icons/database-white.svg")} />
                                <span>Сохранить изменения</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="links-block">
                        <div className="info-block">
                          <div className="title-wrap link-title-wrap">
                            <h3 className="info-block-title">Комнаты</h3>
                          </div>
                          <div className="card-list">
                            {rooms}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default Rooms;