import React, { Component } from 'react';
import windowSize from 'react-window-size';

import UiHeader from '../../components/ui/UiHeader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';
import UiBreadcrumb from '../../components/ui/UiBreadcrumb.js';
import UiLoader from '../../components/ui/modals/UiLoader.js';

import ENV from '../../services/Env.js'
import { getAllDevices } from '../../services/Devices.js';
import { loadUser, getUser, getCitys, updateUser, getBuildings } from '../../services/Users.js';

const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);

class Profile extends Component {

  state = {
    isMenuOpen: false,
    isAccauntOpen: false,
    isInfoOpen: false,
    isAlertShow: false,
    isAddCart: false,
    loader: true,

    user: {
      fio: "",
      city_id: 0,
      zlogin: "",
      zpass: "",
      adress: "",
      city: {
        title: ""
      }
    },

    buildingsList: [],
    citysList: [],
    filesList: [],
    tabIndex: 0,

    fio: "",
    adress: "",
    cityId: 0,
    zlogin: "",
    zpass: "",

    selectedFile: null,
    selectedFileName: "",
    selectedFileTypeTitle: "Выберите тип файла",
    selectedFileType: 0,

    contentHeight: this.props.windowHeight - 130,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();
  }

  _load() {
    let _user = loadUser();
    getUser(_user.id).then((res) => {
      console.log(res);
      if (res.success) {

        getBuildings(_user.id).then((data) => {
          if (data.response) {
            this.setState({
              loader: false,
              user: res.response[0],
              fio: res.response[0].fio,
              adress: res.response[0].adress,
              buildingsList: data.response
            })
          }
        })

      }
    })
    getCitys().then((res) => {
      this.setState({ citysList: res.response })
      //console.log(res);
    })
  }

  showAlert(title, type) {
    this.setState({ isAlertShow: true, alertText: title, alertType: type });
  }

  _updateProfile() {
    updateUser(this.state.user.id, this.state.fio, this.state.adress, this.state.cityId).then((res) => {
      console.log(res);
    });
  }

  render() {
    var cityList = this.state.citysList.map((item, index) => {
      return (
        <option value={item.id} key={index} selected={this.state.user.city_id == item.id} >{item.title}</option>
      )
    })

    var buildingsList = this.state.buildingsList.map((item, index) => {
      return (
        <div key={index}>
          <h3 className="form-title">Настройка соединениес с УД объекта {item.title}</h3>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input type="text" className="form-control" placeholder="ZWAY Логин" defaultValue={item.zlogin} onChange={(e) => this.setState({ zlogin: e.target.value })} />
              </div>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="ZWAY Пароль" defaultValue={item.zpass} onChange={(e) => this.setState({ zpass: e.target.value })} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input type="text" className="form-control" placeholder="ZWAY SESSION ID" defaultValue={item.zbw_sessid} disabled />
              </div>
              <div className="form-group">
                <button type="button" className="btn btn-outline-warning btn-block" onClick={() => this._updateProfile()}>Сохранить</button>
              </div>
            </div>
          </div>
        </div>
      )
    })

    return (
      <div ref={this.divPage} id="page">
        <div className="wrapper">
          <UiHeader />
          <div className="content">
            <UiLeftMenu type={this.state.user.type} fio={this.state.user.fio} />

            <div className="container">

              <div className="card content-card mt-4">
                <div className="card-body" style={{ minHeight: this.state.contentHeight + 'px' }}>
                  <div className="body-inner">
                    <h2 className="body-title">Профиль Пользователя <span>/ {this.state.user.fio}</span></h2>
                    <UiBreadcrumb backPress="/" linksList={[{ value: 0, linkPress: "/profile", linkText: "Профиль" }]} />
                    <div className="body-scroll">
                      <div className="tabs-list">
                        <div className="btn-group btn-group-toggle" data-toggle="buttons">
                          <button className={"btn btn-outline-warning " + (this.state.tabIndex == 0 ? 'active' : null)} onClick={() => this.setState({ tabIndex: 0 })}>Профиль</button>
                          <button className={"btn btn-outline-warning " + (this.state.tabIndex == 1 ? 'active' : null)} onClick={() => this.setState({ tabIndex: 1 })}>Настройки</button>
                        </div>
                      </div>

                      <div className="links-block">
                        <div className="info-block">
                          {this.state.tabIndex == 0 ?
                            <div className="form form-no-indent">
                              <div className="title-wrap link-title-wrap">
                                <h3 className="info-block-title">Профиль</h3>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <input type="text" className="form-control" placeholder="ФИО" defaultValue={this.state.user.fio} onChange={(e) => this.setState({ fio: e.target.value })} />
                                  </div>
                                  <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Адрес" defaultValue={this.state.user.adress} onChange={(e) => this.setState({ adress: e.target.value })} />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <button type="button" className="btn btn-outline-warning btn-block" onClick={() => this._updateProfile()} >Сохранить</button>
                                  </div>
                                  <div className="form-group">
                                    <div className="form-group">
                                      <select className="form-control" onChange={(e) => this.setState({ cityId: e.target.value })}>
                                        {cityList}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            :
                            <div className="form form-no-indent">
                              {buildingsList}

                            </div>
                          }


                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UiLoader show={this.state.loader} />
      </div>

    );
  }
}

export default windowSize(Profile);