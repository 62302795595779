import React, { Component } from 'react';
import { saveUser } from '../../services/Users.js';
import { loadUser } from '../../services/Users.js';

class UiLeftMenu extends Component {

    state = {
        user: {
            fio: "",
            city: {
                title: ""
            }
        }
    }

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) { }

    _logout(e) {
        e.preventDefault();
        saveUser(null);
        window.location.href = "/login";
    }

    render() {

        return (
            <div className={"menu-fixed" + (this.state.toogleMenu ? " active" : "")}>
                <div className="menu-height">
                    <div className={"left-menu " + (this.state.toogleMenu ? "active" : "")}>
                        <div className="left-menu-inner">
                            <div className="menu-top-wrap">
                                <div className="left-menu-control">
                                    <button className="btn btn-icon" onClick={() => this.setState({ toogleMenu: !this.state.toogleMenu })}>
                                        <img src={require("../../assets/img/ui-icons/menu.svg")} />
                                    </button>
                                </div>
                                <a href="/profile" className="profile-wrap">
                                    {this.props.type == 1 || this.props.type == 0 ?
                                        <div className="profile-img-wrap">
                                            <img className="profile-img" src={require("../../assets/img/demo/manager.png")} />
                                            
                                        </div>
                                        :
                                        <div className="profile-img-wrap">
                                            <img className="profile-img" src={require("../../assets/img/demo/profile.jpg")} />

                                            <div className="profile-icon-wrap profile-icon2-wrap">
                                                <img className="profile-icon" src={require("../../assets/img/ui-icons/settings-cog-blue.svg")} />
                                            </div>

                                        </div>
                                    }

                                    <div className="profile-divider"></div>
                                    <p className="profile-info">{this.props.fio}<br />
                                        {this.props.type == 0 ? "Администратор" :
                                            this.props.type == 1 ? "Менеджер" :
                                                this.props.type == 2 ? "Пользователь" :
                                                    this.props.type == 3 ? "Ребенок" : ""}</p>
                                </a>
                            </div>
                            <div className="menu-list">
                                {this.props.type == 2 ?
                                    <a className={"btn menu-item" + (this.props.activePage == 0 ? ' active' : '')} href="/">
                                        <p className="menu-item-text">Главная</p>
                                    </a> : null}
                                {this.props.type == 2 ?
                                    <a className={"btn menu-item" + (this.props.activePage == 1 ? ' active' : '')} href="/scripts">
                                        <p className="menu-item-text">Сценарии</p>
                                    </a>
                                    : null}
                                {this.props.type == 2 ?
                                    <a className={"btn menu-item" + (this.props.activePage == 2 ? ' active' : '')} href="/events">
                                        <p className="menu-item-text">Статусы</p>
                                    </a>
                                    : null}
                                {this.props.type == 2 ?
                                    <a className={"btn menu-item" + (this.props.activePage == 3 ? ' active' : '')} href="/rooms">
                                        <p className="menu-item-text">Планировка дома</p>
                                    </a>
                                    : null}
                                {this.props.type == 2 ?
                                    <a className={"btn menu-item" + (this.props.activePage == 10 ? ' active' : '')} href="/video">
                                        <p className="menu-item-text">Видеонаблюдение</p>
                                    </a> 
                                    : null}
                                {this.props.type == 2 ?
                                    <a className={"btn menu-item" + (this.props.activePage == 4 ? ' active' : '')} href="/devices">
                                        <p className="menu-item-text">Все устройства</p>
                                    </a>
                                    : null}
                                {this.props.type == 2 ?
                                    <a className={"btn menu-item" + (this.props.activePage == 5 ? ' active' : '')} href="/parent">
                                        <p className="menu-item-text">Родительский контроль</p>
                                    </a>
                                    : null}
                                {this.props.type == 2 ?
                                    <a className={"btn menu-item" + (this.props.activePage == 11 ? ' active' : '')} href="/notifications">
                                        <p className="menu-item-text">Уведомления и отчеты</p>
                                    </a>
                                    : null}
                                {this.props.type == 1 || this.props.type == 0 ?
                                    <a className={"btn menu-item" + (this.props.activePage == 9 ? ' active' : '')} href="/houses">
                                        <p className="menu-item-text">Список помещений</p>
                                    </a>
                                    : null}
                                {this.props.type == 1 || this.props.type == 0 ?
                                    <a className={"btn menu-item" + (this.props.activePage == 7 ? ' active' : '')} href="/users">
                                        <p className="menu-item-text">Список пользователей</p>
                                    </a>
                                    : null}
                                {this.props.type == 1 || this.props.type == 0 ?
                                    <a className={"btn menu-item" + (this.props.activePage == 6 ? ' active' : '')} href="/support">
                                        <p className="menu-item-text">Сообщения пользователей</p>
                                    </a>
                                    : null}
                                {this.props.type == 1 || this.props.type == 0 ?
                                    <a className={"btn menu-item" + (this.props.activePage == 8 ? ' active' : '')} href="/alldevices">
                                        <p className="menu-item-text">Список устройств</p>
                                    </a>
                                    : null}
                                <a className="btn menu-item" onClick={(e) => this._logout(e)}>
                                    <p className="menu-item-text">Выход</p>
                                </a>
                            </div>
                            <div className="contact">
                                <p className="contact-text">smartdom.ru 585-100</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UiLeftMenu;