import React, { Component } from 'react';
import windowSize from 'react-window-size';

import UiBreadcrumb from '../../components/ui/UiBreadcrumb.js';
import UiHeader from '../../components/ui/UiHeader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';

import ENV from '../../services/Env.js'
import { loadUser, getUser, getBuildings } from '../../services/Users.js';
import { getAllDevices, onDevice, offDevice, changeMetric, getScripts } from '../../services/Devices.js';
import { storeData, retrieveData } from '../../services/Storage';
import { getAllRooms, addDeviceToRoom } from '../../services/Rooms.js';

class Device extends Component {

  state = {
    selectedDeviceItem: null,
    devicesList: [],
    selectedDevices: [],
    scriptList: [],
    roomsList: [],

    selectedRoomId: 0,
    scriptListLength: 0,

    contentHeight: this.props.windowHeight - 130,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();

    if (!global.selectedBuildingId) {
      //storeData('selectedBuildingId', data.response[0].id);
      global.selectedBuildingId = retrieveData('selectedBuildingId');
    }
  }

  _getTitleType(_title, type) {
    let title = "";
    if (title != null) {
      if (type == 0) title = "Гостиная";
      if (type == 1) title = "Кухня";
      if (type == 2) title = "Спальня";
      if (type == 3) title = "Детская";
      if (type == 4) title = "Коридор";
      if (type == 5) title = "Ванная";
      if (type == 6) title = "Подсобка";
      if (type == 7) title = "Свободная";
      if (type == 8) title = "Кабинет";
      if (type == 9) title = "Столовая";
      if (type == 10) title = "Балкон/лоджия";
      if (type == 11) title = "Сад";
      if (type == 12) title = "Офис";
    } else {
      title = _title;
    }
    return title;
  }

  _load() {
    let _user = loadUser();
    console.log(retrieveData("selectedDeviceItem"))
    this.setState({ user: _user, selectedDeviceItem: retrieveData("selectedDeviceItem"), selectedDevices: retrieveData("selectedDevice") }, () => {
      this._update();

      getScripts(global.selectedBuildingId).then((res) => {
        let arr = [];
        if (res.response) {
          this.state.selectedDeviceItem.devices.map( (item2) => {
            res.response.data.map((item) => {
              if (item.moduleId == "Rules" ){
                if( item.params.simple.triggerEvent.deviceId == item2.device_id){
                  arr.push(item);
                } 
                if(item.params.simple.targetElements.length > 0){
                  if(item.params.simple.targetElements[0].deviceId == item2.device_id){
                    arr.push(item);
                  }
                }
              }
            })
          } )
          
        }
        this.setState({ scriptList: arr });
      })

    });

    


    getAllRooms(_user.id).then((res) => {
      console.log(res)
      this.setState({ roomsList: res.response }, () => {

        let roomId = null;
        this.state.roomsList.map((item) => {
          item.devices.map((item2) => {
            if (item2.device_id == this.state.selectedDevice.id) this.setState({ selectedRoomId: item.id });
          })
        })

      });
    })

  }

  _addDeviceToRoom() {
    addDeviceToRoom(this.state.selectedDeviceItem.id, this.state.selectedDeviceItem.deviceType, this.state.selectedRoomId).then((res) => {
      console.log(res)
      this._load();
    })
  }

  _update() {
    let _user = loadUser();

    getAllRooms(_user.id).then((res) => {
      console.log(res)
      this.setState({ roomsList: res.response });

      getBuildings(_user.id).then((data) => {
        if (data.response) {
          if (data.response.length > 0) {

            data.response.map((item3) => {

              getAllDevices(item3.id).then((res) => {
                if (res.success) {
                  this.setState({ devicesList: res.response });
                  let _newDev = [];
                  this.state.selectedDevices.map((item) => {
                    
                    res.response.data.devices.map((item2) => {
                      if (item2.id == item.id) {
                        _newDev.push(item2);
                        if (item2.metrics.color) {
                          this.setState({ r: item2.metrics.color.r, g: item2.metrics.color.g, b: item2.metrics.color.b });
                        }
                      }
                    })
                  })
                  _newDev = _newDev.concat(this.state.selectedDevices);
                  let filtered = _newDev.filter(function (a) {
                    if (!this[a.id]) {
                      this[a.id] = true;
                      return true;
                    }
                  }, Object.create(null));
                  this.setState({ selectedDevices: filtered });
     
                }
              })

            });


          }
        }
      });



    })


  }

  _changeLevel(_item, target) {
    let _metric = "level=" + target;
    let val = _item;
    val.metrics.level = parseInt(target);
    this.setState({ selectedDevice: val });

    changeMetric(global.selectedBuildingId, _item.id, _metric).then((res) => {
      console.log(res);
      setTimeout(() => this._update(), 5000);
    })
  }

  _changeLevelRGB(_id, e) {
    e.preventDefault();
    let rgbId = this.state.selectedDevice.id;
    let baseId = rgbId.substr(0, rgbId.indexOf('-'));
    let dimmerId = baseId + '-0-38';
    let softId = baseId + '-0-51-0';
    let coldId = baseId + '-0-51-1';



    // perform off for soft&cold if
    offDevice(global.selectedBuildingId, softId).then((res) => {
      console.log(res);
    })
    offDevice(global.selectedBuildingId, coldId).then((res) => {
      console.log(res);
    })
    // run on cmd for dimmer & rgb
    onDevice(global.selectedBuildingId, rgbId).then((res) => {
      console.log(res);
    })
    onDevice(global.selectedBuildingId, dimmerId).then((res) => {
      console.log(res);
    })



    let _metric = "red=" + this.state.r + "&green=" + this.state.g + "&blue=" + this.state.b;
    changeMetric(global.selectedBuildingId, _id, _metric).then((res) => {
      console.log(res);

      setTimeout(() => this._update(), 2500);
    })
  }

  _offClick(_id, checked) {
    if (checked) {
      onDevice(global.selectedBuildingId, _id).then((res) => {
        //console.log(res);
        onDevice(global.selectedBuildingId, _id).then((res) => {
          console.log(res);
          this._update();
        })

      })
    } else {
      offDevice(global.selectedBuildingId, _id).then((res) => {
        //console.log(res);
        offDevice(global.selectedBuildingId, _id).then((res) => {
          console.log(res);
          this._update();
        })
      })
    }
  }

  render() {
    if (this.state.selectedDeviceItem) {
      var roomsOptionsList = this.state.roomsList.map((item, index) => {
        return (
          <option value={item.id} key={index} selected={this.state.selectedRoomId == item.id} >{this._getTitleType(item.title, item.type)}</option>
        )
      });

      var devicesList = this.state.selectedDevices.map((item, index) => {
        return (
          <div className="device-settings" key={index}>
            {item.metrics.color ?
              <div className="row">
                <div className="col-4">
                  <div className="range-box">
                    <h3 className="range-value">{item.metrics.color.r}</h3>
                    <p className="range-value-label">Красный</p>
                    <input type="range" min="0" max="255" step="1" onChange={e => this.setState({ r: e.target.value })} />
                  </div>
                </div>
                <div className="col-4">
                  <div className="range-box">
                    <h3 className="range-value">{item.metrics.color.g}</h3>
                    <p className="range-value-label">Зеленый</p>
                    <input type="range" min="0" max="255" step="1" onChange={e => this.setState({ g: e.target.value })} />
                  </div>
                </div>
                <div className="col-4">
                  <div className="range-box">
                    <h3 className="range-value">{item.metrics.color.b}</h3>
                    <p className="range-value-label">Голубой</p>
                    <input type="range" min="0" max="255" step="1" onChange={e => this.setState({ b: e.target.value })} />
                  </div>
                </div>
                <div className="col-4">
                  <div className="range-box">
                    <div style={{ width: "200px", height: "200px", background: "rgb(" + this.state.r + "," + this.state.g + "," + this.state.b + ")" }} ></div>
                  </div>
                </div>

              </div>
              : null
            }

            {item.deviceType == "switchBinary" || item.deviceType == "switchControl" || item.deviceType == "switchBinary (Thermostat)" ?
              item.metrics.level.toFixed ?
                <div className="switch-box">
                 
                  <div className="btn-list">
                    <h3 className="range-value">{item.metrics.title}</h3>
                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                      <button className={"btn btn-outline-warning " + (item.metrics.level > 0 ? "active" : null)} onClick={() => this._offClick(item.id, true)}>Включено</button>
                      <button className={"btn btn-outline-warning " + (item.metrics.level == 0 ? "active" : null)} onClick={() => this._offClick(item.id, false)}>Выключенно</button>
                    </div>
                  </div>

                </div>
                :
                <div className="switch-box">
                
                  <div className="btn-list">
                    <h3 className="range-value">{item.metrics.title}</h3>
                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                      <button className={"btn btn-outline-warning " + (item.metrics.level == "on" ? "active" : null)} onClick={() => this._offClick(item.id, true)}>Включено</button>
                      <button className={"btn btn-outline-warning " + (item.metrics.level != "on" ? "active" : null)} onClick={() => this._offClick(item.id, false)}>Выключенно</button>
                    </div>
                  </div>

                </div>
              : null}


            {item.metrics.level.toFixed ?
              <div className="row">
                <div className="col-6">
                  <div className="range-box">
                    <h3 className="range-value">{item.metrics.level} {item.metrics.scaleTitle ? item.metrics.scaleTitle : '%'}</h3>
                    <p className="range-value-label">Уровень {item.metrics.title}</p>
                    {item.deviceType == "switchMultilevel" || item.deviceType == "switchMultilevel (Blinds)" ?
                      <input
                        type="range"

                        value={item.metrics.level}
                        onChange={e => this._changeLevel(item, e.target.value)}
                        min={item.metrics.min}
                        max={item.metrics.max}
                        step="10" />
                      : null}
                  </div>
                </div>
              </div>
              : null}

            {item.metrics.color ? <button className="btn btn-warning" onClick={(e) => this._changeLevelRGB(item.id, e)}>Установить цвет</button> : null}

          </div>
        )
      })

      var scipts = this.state.scriptList.map( (item,index) => {
        return (
        <a className="btn btn-info btn-sm" key={index} href="/scripts">{item.title}</a>
                           
        )
      })

      return (
        <div ref={this.divPage} id="page">
          <div className="wrapper">
            <UiHeader />
            <div className="content">
              <UiLeftMenu type={this.state.user.type} city={this.state.user.city.title} fio={this.state.user.fio} />
              <div className="container">
                <div className="card content-card mt-4">
                  <div className="card-body" style={{ minHeight: this.state.contentHeight + 'px' }}>
                    <div className="body-inner">
                      <h2 className="body-title">{this.state.selectedDeviceItem.title} <span> </span></h2>
                      <UiBreadcrumb backPress="/" linksList={[{ value: 0, linkPress: "/devices", linkText: "Все устройства" }, { value: 1, linkPress: "/device", linkText: this.state.selectedDeviceItem.title }]} />

                      <div className="body-scroll">

                        <div className="card card-gray form">
                          <h3 className="form-title">Прикрепить к комнате</h3>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <select className="form-control" onChange={(e) => this.setState({ selectedRoomId: e.target.value })}>
                                  {roomsOptionsList}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <button type="button" className="btn btn-outline-warning btn-block" onClick={() => this._addDeviceToRoom()}>Сохранить</button>
                              </div>
                            </div>
                          </div>
                        </div>

                        {devicesList}

                        <div className="links-block">
                          <div className="info-block">
                            <div className="title-wrap link-title-wrap">
                              <h3 className="info-block-title">Сценарии</h3>
                              <a className="btn btn-sm btn-info" href="/scripts">&nbsp;+&nbsp;</a>
                            </div>
                            {this.state.scriptList.length > 0 ?
                              <div className="card-list card-list-lines">
                                {scipts}
                              </div>
                              :
                              <div className="empty-list">
                                <img src={require('../../assets/img/device/house-automation.svg')} />
                                <div className="empty-list-info">
                                  <h3>У вас нет сценариев для этого устройства</h3>
                                  <a className="btn btn-info btn-sm" href="/scripts">Перейти к сценариям</a>
                                </div>
                              </div>
                            }
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      );
    } else {
      return null;
    }

  }
}

export default windowSize(Device);