import React, { Component } from 'react';
import { getAllDevices, onDevice, offDevice, changeMetric } from '../../../services/Devices.js';

class UiDeviceGroupCard extends Component {

    state = {
        moreVisible: false,
    }

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) { }


    _changeLevel(_item, target) {
        let _metric = "level=" + target;
        let val = _item;
        val.metrics.level = parseInt(target);
        this.setState({ selectedDevice: val });

        changeMetric(_item.buildingId, _item.id, _metric).then((res) => {
            console.log(res);
            setTimeout(() => this.props.update(), 5000);
        })
    }

    _changeLevelRGB( _item, _id, e) {
        e.preventDefault();
        let rgbId = this.state.selectedDevice.id;
        let baseId = rgbId.substr(0, rgbId.indexOf('-'));
        let dimmerId = baseId + '-0-38';
        let softId = baseId + '-0-51-0';
        let coldId = baseId + '-0-51-1';



        // perform off for soft&cold if
        offDevice(_item.buildingId , softId).then((res) => {
            console.log(res);
        })
        offDevice(_item.buildingId , coldId).then((res) => {
            console.log(res);
        })
        // run on cmd for dimmer & rgb
        onDevice(_item.buildingId , rgbId).then((res) => {
            console.log(res);
        })
        onDevice(_item.buildingId , dimmerId).then((res) => {
            console.log(res);
        })



        let _metric = "red=" + this.state.r + "&green=" + this.state.g + "&blue=" + this.state.b;
        changeMetric(_item.buildingId, _id, _metric).then((res) => {
            console.log(res);

            setTimeout(() => this._update(), 2500);
        })
    }

    _offClick(_bid, _id, checked) {
        this.props.showLoader();
        if (checked) {
            onDevice(_bid, _id).then((res) => {
                //console.log(res);
                onDevice(_bid, _id).then((res) => {
                    console.log(res);
                    this.props.update();
                })

            })
        } else {
            offDevice(_bid, _id).then((res) => {
                //console.log(res);
                offDevice(_bid, _id).then((res) => {
                    console.log(res);
                    this.props.update();
                })
            })
        }
    }

    _getMetrics(_metrics) {
        
        let dd = _metrics.map((item, index) => {
            let text = "";
            if (item.deviceType == "battery") {
                text = text + " " + (_metrics.length > 1 ? item.metrics.title : "") + " " + (item.metrics.level == "on" || item.metrics.level > 0 ? "Вкл" : "Выкл");
            } else if (item.deviceType == "doorlock") {
                text = text + " " + (_metrics.length > 1 ? item.metrics.title : "") + " " + (item.metrics.level == "on" || item.metrics.level > 0 ? "Вкл" : "Выкл");
            } else if (item.deviceType == "switchBinary (Thermostat)") {
                text = text + " " + (_metrics.length > 1 ? item.metrics.title : "") + " " + (item.metrics.level == "on" || item.metrics.level > 0 ? "Вкл" : "Выкл");
            } else if (item.deviceType == "switchBinary") {
                text = text + " " + (_metrics.length > 1 ? item.metrics.title : "") + " " + (item.metrics.level == "on" || item.metrics.level > 0 ? "Вкл" : "Выкл");
            } else if (item.deviceType == "switchMultilevel") {
                text = text + " " + (_metrics.length > 1 ? item.metrics.title : "") + " " + (item.metrics.level == "on" || item.metrics.level > 0 ? "Вкл" : "Выкл");
            } else if (item.deviceType == "switchMultilevel (Blinds)") {
                text = text + " " + (_metrics.length > 1 ? item.metrics.title : "") + " " + (item.metrics.level == "on" || item.metrics.level > 0 ? "Вкл" : "Выкл");
            } else if (item.deviceType == "sensorBinary") {
                text = text + " " + (item.metrics.level);
            } else if (item.deviceType == "sensorMultilevel") {
                text = text + " " + (item.metrics.level);
            } else if (item.deviceType == "toggleButton") {
                text = text + " " + (_metrics.length > 1 ? item.metrics.title : "") + " " + (item.metrics.level == "on" || item.metrics.level > 0 ? "Вкл" : "Выкл");
            } else if (item.deviceType == "camera") {
                text = text + " " + item.metrics.title;
            } else if (item.deviceType == "switchControl") {
                text = text + " " + (_metrics.length > 1 ? item.metrics.title : "") + " " + (item.metrics.level == "on" || item.metrics.level > 0 ? "Вкл" : "Выкл");
            } else if (item.deviceType == "text") {
                text = text + " " + item.metrics.title;
            } else if (item.deviceType == "sensorMultiline") {
                text = text + " " + (item.metrics.level);
            } else if (item.deviceType == "switchRGB") {
                text = text + " " + (_metrics.length > 1 ? item.metrics.title : "") + " " + "R:" + item.metrics.color.r + " G:" + item.metrics.color.g + " B:s" + item.metrics.color.b;
            } else if (item.deviceType == "switchRGBW") {
                text = text + " " + (_metrics.length > 1 ? item.metrics.title : "") + " " + "R: " + item.metrics.color.r + " G: " + item.metrics.color.g + " B: " + item.metrics.color.b;
            }

            return (
                <p key={index}>
                    {item.deviceType == "sensorBinary" || item.deviceType == "sensorMultilevel" ?
                        <img className="device-card-icon-small" src={require("../../../assets/img/device/smart-app.svg")} />
                        :
                        <img className="device-card-icon-small" src={require("../../../assets/img/device/console.svg")} />
                    }{text}{" "}
                    <span>
                        {(item.metrics.scaleTitle ? item.metrics.scaleTitle : null)}
                    </span>
                </p>
            )
        })


        return dd;
    }

    render() {

        var devicesList = this.props.params.map((item, index) => {
            return (
                <div key={index}>
                    {item.metrics.color ?
                        <div  >

                            <div className="range-wrap">
                                <p className="range-value-label">{item.metrics.color.r}</p>
                                <p className="range-value-label">Красный</p>
                                <input type="range" min="0" max="255" step="1" onChange={e => this.setState({ r: e.target.value })} />
                            </div>
                            <div className="range-wrap">
                                <p className="range-value-label">{item.metrics.color.r}</p>
                                <p className="range-value-label">Зеленый</p>
                                <input type="range" min="0" max="255" step="1" onChange={e => this.setState({ g: e.target.value })} />
                            </div>
                            <div className="range-wrap">
                                <p className="range-value-label">{item.metrics.color.r}</p>
                                <p className="range-value-label">Голубой</p>
                                <input type="range" min="0" max="255" step="1" onChange={e => this.setState({ b: e.target.value })} />
                            </div>



                            <div className="range-box">
                                <div style={{ width: "200px", height: "200px", background: "rgb(" + this.state.r + "," + this.state.g + "," + this.state.b + ")" }} ></div>
                            </div>


                        </div>
                        : null
                    }

                    {item.deviceType == "switchBinary" || item.deviceType == "switchControl" || item.deviceType == "switchBinary (Thermostat)" ?
                        item.metrics.level.toFixed ?
                            <button onClick={() =>  this._offClick(item.buildingId, item.id, item.metrics.level == 0 ) } className={"switch" + (item.metrics.level > 0 ? " active" : "")}>
                                <div className="switch-inner"></div>
                                <div className="switch-label">
                                    <p>{item.metrics.title}</p>
                                </div>
                            </button>
                            :
                            <button onClick={() => this._offClick(item.buildingId, item.id, item.metrics.level != "on" ) } className={"switch" + (item.metrics.level == "on" ? " active" : "")}>
                                <div className="switch-inner"></div>
                                <div className="switch-label">
                                    <p>{item.metrics.title}</p>
                                </div>
                            </button>
                        : null}


                    {(item.metrics.level.toFixed && (item.deviceType == "switchMultilevel" || item.deviceType == "switchMultilevel (Blinds)")) ?
                        <div className="range-wrap">
                            <p className="range-value-label">Уровень {item.metrics.title}</p>
                            <input type="range" min={item.metrics.min} max={item.metrics.max} step="1" value={item.metrics.level}  onChange={e => this._changeLevel(item, e.target.value)}   />
                        </div>
                        : null}

                    {item.metrics.color ? <button className="btn btn-warning" onClick={(e) => this._changeLevelRGB(item, item.id, e)}>Установить цвет</button> : null}

                </div>
            )
        })


        return (
            <div className="btn card device-card">
                {this.props.deviceType == "battery" ?
                    <div className="device-card-icon-wrap">
                        <img className="device-card-icon" src={require("../../../assets/img/device/battery.svg")} />
                        <img className="device-card-icon-active" src={require("../../../assets/img/device/battery-white.svg")} />
                    </div>
                    :
                    (this.props.deviceType == "switchRGB" ?
                        <div className="device-card-icon-wrap">
                            <img className="device-card-icon" src={require("../../../assets/img/device/light.svg")} />
                            <img className="device-card-icon-active" src={require("../../../assets/img/device/light-white.svg")} />
                        </div>
                        :
                        (this.props.deviceType == "switchRGBW" ?
                            <div className="device-card-icon-wrap">
                                <img className="device-card-icon" src={require("../../../assets/img/device/light.svg")} />
                                <img className="device-card-icon-active" src={require("../../../assets/img/device/light-white.svg")} />
                            </div>
                            :
                            (this.props.deviceType == "switchControl" ?
                                <div className="device-card-icon-wrap">
                                    <img className="device-card-icon" src={require("../../../assets/img/device/switch.svg")} />
                                    <img className="device-card-icon-active" src={require("../../../assets/img/device/switch-white.svg")} />
                                </div>
                                :
                                (this.props.deviceType == "sensorMultilevel" ?
                                    <div className="device-card-icon-wrap">
                                        <img className="device-card-icon" src={require("../../../assets/img/device/sensor.svg")} />
                                        <img className="device-card-icon-active" src={require("../../../assets/img/device/sensor-white.svg")} />
                                    </div>
                                    :
                                    (this.props.deviceType == "sensorMultiline" ?
                                        <div className="device-card-icon-wrap">
                                            <img className="device-card-icon" src={require("../../../assets/img/device/sensor.svg")} />
                                            <img className="device-card-icon-active" src={require("../../../assets/img/device/sensor-white.svg")} />
                                        </div>
                                        :
                                        (this.props.deviceType == "camera" ?
                                            <div className="device-card-icon-wrap">
                                                <img className="device-card-icon" src={require("../../../assets/img/device/cctv.svg")} />
                                                <img className="device-card-icon-active" src={require("../../../assets/img/device/cctv-white.svg")} />
                                            </div>
                                            :
                                            (this.props.deviceType == "doorlock" ?
                                                <div className="device-card-icon-wrap">
                                                    <img className="device-card-icon" src={require("../../../assets/img/device/protection.svg")} />
                                                    <img className="device-card-icon-active" src={require("../../../assets/img/device/protection-white.svg")} />
                                                </div>
                                                :
                                                (this.props.deviceType == "thermostat" ?
                                                    <div className="device-card-icon-wrap">
                                                        <img className="device-card-icon" src={require("../../../assets/img/device/temperature.svg")} />
                                                        <img className="device-card-icon-active" src={require("../../../assets/img/device/temperature-white.svg")} />
                                                    </div>
                                                    :
                                                    (this.props.deviceType == "switchBinaryThermostat" ?
                                                        <div className="device-card-icon-wrap">
                                                            <img className="device-card-icon" src={require("../../../assets/img/device/temperature.svg")} />
                                                            <img className="device-card-icon-active" src={require("../../../assets/img/device/temperature-white.svg")} />
                                                        </div>
                                                        :
                                                        (this.props.deviceType == "switchBinary" ?
                                                            <div className="device-card-icon-wrap">
                                                                <img className="device-card-icon" src={require("../../../assets/img/device/switch.svg")} />
                                                                <img className="device-card-icon-active" src={require("../../../assets/img/device/switch-white.svg")} />
                                                            </div>
                                                            :
                                                            (this.props.deviceType == "sensorBinary" ?
                                                                <div className="device-card-icon-wrap">
                                                                    <img className="device-card-icon" src={require("../../../assets/img/device/sensor.svg")} />
                                                                    <img className="device-card-icon-active" src={require("../../../assets/img/device/sensor-white.svg")} />
                                                                </div>
                                                                :
                                                                (this.props.deviceType == "switchMultilevel" ?
                                                                    <div className="device-card-icon-wrap">
                                                                        <img className="device-card-icon" src={require("../../../assets/img/device/console.svg")} />
                                                                        <img className="device-card-icon-active" src={require("../../../assets/img/device/console-white.svg")} />
                                                                    </div>
                                                                    :
                                                                    (this.props.deviceType == "switchMultilevelBlinds" ?
                                                                        <div className="device-card-icon-wrap">
                                                                            <img className="device-card-icon" src={require("../../../assets/img/device/console.svg")} />
                                                                            <img className="device-card-icon-active" src={require("../../../assets/img/device/console-white.svg")} />
                                                                        </div>
                                                                        :
                                                                        (this.props.deviceType == "toggleButton" ?
                                                                            <div className="device-card-icon-wrap">
                                                                                <img className="device-card-icon" src={require("../../../assets/img/device/switch.svg")} />
                                                                                <img className="device-card-icon-active" src={require("../../../assets/img/device/switch-white.svg")} />
                                                                            </div>
                                                                            :
                                                                            (this.props.deviceType == "text" ?
                                                                                <div className="device-card-icon-wrap">
                                                                                    <img className="device-card-icon" src={require("../../../assets/img/device/smart-app.svg")} />
                                                                                    <img className="device-card-icon-active" src={require("../../../assets/img/device/smart-app-white.svg")} />
                                                                                </div>
                                                                                :
                                                                                <div className="device-card-icon-wrap">
                                                                                    <img className="device-card-icon" src={require("../../../assets/img/device/smarthouse.svg")} />
                                                                                    <img className="device-card-icon-active" src={require("../../../assets/img/device/smarthouse-white.svg")} />
                                                                                </div>
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                }
                <h3 className="device-card-title">{this.props.title}</h3>

                {devicesList}
                <p className="device-card-text">{this._getMetrics(this.props.params)}</p>
                <p className="device-card-text"><span>{this.props.room}</span></p>
                <button className="status-wrap" onClick={() => this.setState({ moreVisible: !this.state.moreVisible })}>
                    <div className={"status-icon" + (this.props.status ? " on" : " off")}></div>
                    <div className={"status-icon" + (this.props.status ? " on" : " off")}></div>
                    <div className={"status-icon" + (this.props.status ? " on" : " off")}></div>
                </button>

                <div className="select-bar">
                    { this.props.hasFavorite ? <button className="select-bar-btn" onClick={(e) => this.props.onPress(e)}>
                        <img src={require('../../../assets/img/ui-icons/heart.svg')} />
                        <span>Добавить в избранные</span>
                    </button> : null}
                    <button className="select-bar-btn" onClick={(e) => this.props.onPressOpen(e)}>
                        <img src={require('../../../assets/img/ui-icons/circle.png')} />
                        <span>Подробная информация</span>
                    </button>
                </div>

            </div>
        );
    }

}

export default UiDeviceGroupCard;