import React, { Component } from 'react';

class UiShowDevices extends Component {

    state = {
        selectedId: null,
    }

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.show && this.state.selectedId == null) {
            let _arr = [];

        }
    }

    _isSelected(_id){
        let f = -1;
        this.props.groupsDevices.map((item) => {
            if(item.device_id == _id){
                f = item.group_id;
            } 
        })
        return f;
    }

    _getGroupsItems(_id){
        var group = this.props.groups.map((item, index) => {
            return (
                <option key={index} value={item.id} selected={this._isSelected(_id) == item.id} >{item.title}</option>
            )
        });
        return group;
    }

    render() {


        var tablesList = this.props.devices.map((item, index) => {
            return (
                <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{item.metrics.title} {item.metrics.probeTitle ? item.metrics.probeTitle : ""} {item.metrics.scaleTitle ? item.metrics.scaleTitle : ""}</td>
                    <td>
                        {item.title}
                    </td>
                    <td>
                        <select className="form-control" onChange={(e) => this.props.addToGroup(item.id, e.target.value) }>
                            <option value="-1"   > -- </option>
                            {this._getGroupsItems(item.id)}
                        </select>
                    </td>
                    <td>

                    </td>
                </tr>
            )
        })

        return (
            <div className={"alert-wrap " + (this.props.show ? "show" : "hide")}>
                <div className="card modal-card">
                    <div className="modal-card-inner">

                        <h3 className="form-title">Доступные устройства для {this.props.userTitle}</h3>

                        <div className="row">
                            <div className="col-md-12">
                                {tablesList.length > 0 ?
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Название</th>
                                              
                                                <th scope="col">Помещение</th>
                                                <th scope="col">Группа</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tablesList}
                                        </tbody>
                                    </table>

                                    : null}

                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <input type="text" className="form-control" id="titleInput" placeholder="Название группы" onChange={(e) => this.setState({ groupTitle: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <button type="button" className="btn btn-outline-info btn-block" onClick={() => {
                                    document.getElementById("titleInput").value = "";
                                    this.props.addGroup(this.state.groupTitle);
                                    this.setState({groupTitle: ""});
                                }} >Создать группу устройства</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">

                        </div>
                        <div className="col-md-6">
                            <button type="button" className="btn btn-outline-info btn-block" onClick={() => this.props.cancelPress()} >Закрыть</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UiShowDevices;