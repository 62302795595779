import React, { Component } from 'react';

import UiHeader from '../../components/ui/UiHeader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';
import UiDeviceCard from '../../components/ui/cards/UiDeviceCard.js';
import UiDeviceGroupCard from '../../components/ui/cards/UiDeviceGroupCard.js';
import UiScriptSmallCard from '../../components/ui/cards/UiScriptSmallCard.js';
import UiRoomCard from '../../components/ui/cards/UiRoomCard.js';
import UiLoader from '../../components/ui/modals/UiLoader.js';
import UiAlert from '../../components/ui/modals/UiAlert.js';

import ENV from '../../services/Env.js'
import { getScripts, getAllDevices } from '../../services/Devices.js';
import { loadUser, getUser, getBuildings, getWeather } from '../../services/Users.js';
import { storeData, retrieveData } from '../../services/Storage';
import { getAllRooms, getAllPlans } from '../../services/Rooms.js';



const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);


class Home extends Component {

  state = {
    isMenuOpen: false,
    isAccauntOpen: false,
    isInfoOpen: false,
    isAlertShow: false,
    isAddCart: false,
    loader: false,

    groupsDevices: [],
    buildingsList: [],
    roomsList: [],
    devicesList: [],
    rulesScriptList: [],
    planImage: null,
    user: {
      adress: "",
      city: {
        title: ""
      }
    },

    weather: {
      temp: 10,
      description: "",
      icon: "02n"
    },

    roomsSize: 0,

    selectedFile: null,
    selectedFileName: "",
    selectedFileTypeTitle: "Выберите тип файла",
    selectedFileType: 0,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();
  }

  _getTitleBuilding(_id) {
    let title = "";
    this.state.buildingsList.map((item) => {
      if (_id = item.id) title = item.title;
    })
    return title;
  }

  _load() {
    console.log(global.user);

    let _user = loadUser();
    let _arr = retrieveData('favoriteArr');

    global.selectedBuildingId = retrieveData('selectedBuildingId');
    this.setState({ groupsDevices: _arr ? _arr : [] });

    if (_user == null) {
      this.props.history.push("/login")
    } else {
      if (_user.type == 0 || _user.type == 1) {
        this.props.history.push("/houses")
      }
      this.setState({ user: _user, loader: true });

      getAllPlans(_user.id).then((res) => {
        console.log(res)
        if (res.response.length > 0) {
          let img = new DOMParser().parseFromString(res.response[0].svg, 'image/svg+xml');
          this.setState({ planImage: res.response[0].svg }, () => {
            document.getElementById("stage").innerHTML = img.documentElement.innerHTML;
          });
        }
      });

      getBuildings(_user.id).then((data) => {
        if (data.response) {
          if (data.response.length > 0) {
            //  if (!global.selectedBuildingId) {
            global.selectedBuildingId = data.response[0].id;
            storeData('selectedBuildingId', data.response[0].id);
            //}
            console.log("getBuildings", data.response, global.selectedBuildingId);

            this.setState({
              devicesList: [],
              buildingsList: data.response,
              buildingTitle: data.response.length > 0 ? data.response[0].title : null
            }, () => {

              data.response.map((item) => {
                getAllDevices(item.id).then((res) => {
                  if (res.response) {
                    console.log("getAllDevices", res.response.data.devices)
                    res.response.data.devices.map((item2) => {
                      item2.buildingTitle = item.title;
                      item2.buildingId = item.id;
                    });
                    if (res.success) {
                      let _arr = this.state.devicesList;
                      _arr = _arr.concat(res.response.data.devices);
                      this.setState({ devicesList: _arr, loader: false });
                    }
                  } else {
                    this.setState({ loader: false });
                  }
                })
              })

            });


            getScripts(global.selectedBuildingId).then((res) => {
              console.log("getScripts", res)
              if (res.response) {
                let arr = [];
                let arr2 = [];
                res.response.data.map((item) => {
                  if (item.moduleId == "Rules") arr.push(item);
                })
                this.setState({ rulesScriptList: arr });
              }
            });


          } else {
            this.setState({ loader: false, showAlertNullBuildings: true });

          }
        }
      })


      getAllRooms(_user.id).then((res) => {
        console.log(res)
        let size = 0;
        res.response.map((item) => {
          size = size + item.size;
        })
        this.setState({ roomSize: size, roomsList: res.response })
      })
      if (_user.city) {
        getWeather(_user.city.title).then((res) => {
          console.log("getWeather", res)
          if (res) {
            if (res.cod != 404) {
              var obj = {
                temp: Math.round(res.main.temp),
                description: res.weather[0].description,
                icon: res.weather[0].icon
              };
              this.setState({ weather: obj })
            }
          }

        });
      }



    }


  }


  showAlert(title, type) {
    this.setState({ isAlertShow: true, alertText: title, alertType: type });
  }

  _getRoomByDevice(_device_id) {
    let title = null;
    this.state.roomsList.map((item) => {
      item.devices.map((item2) => {
        if (item2.device_id == _device_id) title = this._getTitleType(item.title, item.type);
      })
    })
    return title;
  }

  _getDeviceType(_id) {
    let _f = "";
    this.state.devicesList.map((item) => {
      if (_id == item.id) {
        _f = item.deviceType;
      }
    })
    return _f;
  }

  _checkGroupStatus(_devices) {
    let _f = false;
    _devices.map((item2) => {
      this.state.devicesList.map((item) => {
        if (item2.device_id == item.id) {
          if (parseInt(item.metrics.level) > 0 || item.metrics.level == "on") _f = true;
        }
      })
    })
    return _f;
  }

  _getGroupMetrics(_devices) {
    let _arr = [];
    _devices.map((item2) => {
      this.state.devicesList.map((item) => {
        if (item2.device_id == item.id) {
          _arr.push(item);
        }
      })
    })

    return _arr;
  }


  _getTurnedDevices() {
    let count = 0;
    var devices = this.state.devicesList.map((item) => {
      if (parseInt(item.metrics.level) > 0 || item.metrics.level == "on") count++;
    });
    return count;
  }

  render() {

    var devices = this.state.groupsDevices.map((item, index) => {

      return (
        <UiDeviceGroupCard
          key={index}
          deviceType={this._getDeviceType(item.devices[0].device_id)}
          title={item.title}
          params={this._getGroupMetrics(item.devices)}
          room={this._getRoomByDevice(item.id)}
          status={this._checkGroupStatus(item.devices)}
          update={() => this._load()}
          showLoader={() => this.setState({ loader: true })}

          onPressOpen={(e) => {
            e.preventDefault();
            console.log(item.probeType)
            if (item.probeType != '') {
              storeData("selectedDevice", this._getGroupMetrics(item.devices));
              storeData("selectedDeviceItem", item);
              this.props.history.push("/device")
            }
          }}
        />
      )

    });

    var rooms = this.state.roomsList.map((item, index) => {
      return (
        <UiRoomCard key={index} roomType={item.type} type={item.type} title={item.title} devices={item.devices.length} />
      )
    });

    var scripts = this.state.rulesScriptList.map((item, index) => {
      return (
        <UiScriptSmallCard
          key={index}
          active={item.active}
          deviceType="plug"
          title={item.title}
          onClick={() => {
            this.props.history.push("/scripts")
          }}
        />
      )
    })

    var buildingsListItems = this.state.buildingsList.map((item, index) => {
      return (
        <option value={item} key={index} >{item.title}</option>
      )
    })

    return (
      <div ref={this.divPage} id="page">
        <UiLoader show={this.state.loader} />
        <UiAlert
          show={this.state.showAlert}
          callBack={() => this.setState({ showAlert: false })}
          title="Внимание!"
          text="Плата не доступна. Проверьте состояние платы управления."
          leftBtnText="Ok"
        />
        <UiAlert
          show={this.state.showAlertNullBuildings}
          callBack={() => this.setState({ showAlertNullBuildings: false })}
          title="Внимание!"
          text="У вас нет созданных объектов"
          leftBtnText="Ok"
        />
        <div className="wrapper">
          <UiHeader />
          <div className="content">
            <UiLeftMenu type={this.state.user.type} fio={this.state.user.fio} activePage={0} />
            <div className="container">

              <div className="card content-card mt-4">
                <div className="card-body">
                  <div className="body-inner no-breadcrumb">
                    <h2 className="body-title">Панель Управления <span>/ {this.state.buildingTitle}</span></h2>
                    <div className="body-scroll">
                      <div className="card card-gray">
                        <div className="row">
                          <div className="col-4">
                            <div className="info-card">
                              <img className="info-card-icon" src={require("../../assets/img/device/smart-app.svg")} />
                              <p className="info-card-text">Выбрать объект</p>
                            </div>
                          </div>
                          <div className="col-8">
                            <div className="info-card">
                              <select className="form-control" onChange={(e) => {
                                this.setState({
                                  selectedBuildingId: e.target.value.id,
                                  buildingTitle: e.target.value.title,
                                });
                                global.selectedBuildingId = e.target.value.id;
                                storeData("selectedBuildingId", e.target.value.id);
                              }}>
                                {buildingsListItems}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="card card-gray">
                        <div className="row">
                          <div className="col-4">
                            <div className="info-card">
                              <img className="info-card-icon" src={"http://openweathermap.org/img/wn/" + this.state.weather.icon + "@2x.png"} />
                              <p className="info-card-text">{this.state.weather.description}</p>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="info-card">
                              <h3 className="info-card-title">{+this.state.weather.temp}<span>°C</span></h3>
                              <p className="info-card-text">Снаружи</p>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="info-card">
                              <h3 className="info-card-title">--<span>°C</span></h3>
                              <p className="info-card-text">Внутри</p>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="info-block">
                        <div className="title-wrap">
                          <h3 className="info-block-title">Электроэнергия за сегодня</h3>
                          <p className="info-block-num">12 <span>кВт/ч</span></p>
                        </div>
                      </div>

                      <div className="info-block">
                        <div className="title-wrap">
                          <h3 className="info-block-title">Cценарии</h3>
                        </div>
                        {this.state.rulesScriptList.length > 0 ?
                          <div className="card-list card-list-scroll">
                            {scripts}
                          </div>
                          :
                          <div className="empty-list">
                            <img src={require('../../assets/img/device/house-automation.svg')} />
                            <div className="empty-list-info">
                              <h3>У вас нет сценариев для этого устройства</h3>
                              <a className="btn btn-info btn-sm" href="/scripts">Перейти к сценариям</a>
                            </div>
                          </div>
                        }
                      </div>

                      <div className="info-block">
                        <div className="title-wrap">
                          <h3 className="info-block-title">Текущая планировка</h3>
                        </div>
                        {!this.state.planImage ?
                          <div className="card card-gray empty-card">
                            <p>Вы не добавили планировку</p>
                            <button className="btn btn-outline-warning">Добавить</button>
                          </div>
                          :
                          <a className="card home-plan-wrap" href="/rooms">
                            <div className="home-plan">
                              <svg id="stage" width="600" height="600" viewBox="0 0 600 600"></svg>
                            </div>
                            <div className="home-plan-shadow">
                              <p>Перейти к планировке</p>
                            </div>
                          </a>
                        }
                      </div>

                      <div className="card card-gray">
                        <div className="row">
                          <div className="col-6">
                            <div className="info-img-card">
                              <img className="info-img-card-icon" src={require("../../assets/img/electricity/plug.svg")} />
                              <div className="info-img-card-wrap">
                                <h3 className="info-img-card-title">{this.state.devicesList.length} <span>шт</span></h3>
                                <p className="info-img-card-text">Устройств</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="info-img-card">
                              <img className="info-img-card-icon" src={require("../../assets/img/electricity/bolt.svg")} />
                              <div className="info-img-card-wrap">
                                <h3 className="info-img-card-title">{this._getTurnedDevices()} <span>шт</span></h3>
                                <p className="info-img-card-text">Включены</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="links-block">
                        <div className="info-block">
                          <div className="title-wrap link-title-wrap">
                            <h3 className="info-block-title">Избранные устройства</h3>
                            <p className="info-block-num"><a href="/devices">ВСЕ</a></p>
                          </div>
                          <div className="card-list">
                            {devices}
                            {devices.length == 0 ? <p className="info-img-card-text">Добавьте свои устройства в избранное</p> : null}
                          </div>
                        </div>

                        {rooms.length > 0 ?
                          <div className="info-block">
                            <div className="title-wrap link-title-wrap">
                              <h3 className="info-block-title m-top-0">Комнаты</h3>
                              <p className="info-block-num m-top-0"></p>
                            </div>
                            <div className="card-list">
                              {rooms}
                            </div>
                          </div>
                          : ""}



                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default Home;