import React, { Component } from 'react';
import windowSize from 'react-window-size';
import { useParams } from "react-router-dom";

import UiLoader from '../../components/ui/modals/UiLoader.js';
import UiHeader from '../../components/ui/UiHeader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';
import UiBreadcrumb from '../../components/ui/UiBreadcrumb.js';
import UiShowDevices from '../../components/ui/modals/UiShowDevices.js';


import ENV from '../../services/Env.js'

import { getAllDevices, addGroup, getAllGroupsDevices, addDeviceToGroup, getAllGroups } from '../../services/Devices.js';
import { loadUser, getUsers, getBuildings } from '../../services/Users.js';
import { getAllRooms, addRoom, getAllPlans, savePlan } from '../../services/Rooms.js';

const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);


class AllDevices extends Component {

  state = {
    user: {
      adress: "",
      city: {
        title: ""
      }
    },

    contentHeight: this.props.windowHeight - 130,

    groups: [],
    groupsDevices: [],
    deviceListArr: [],
    usersList: [],
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let _user = loadUser();
    this.setState({ user: _user }, () => this._load())
  }

  _load() {

    getUsers().then((res) => {
      console.log(res)
      let _user = [];
      res.response.map((item) => {
        if (item.type == 2) _user.push(item);
      })
      this.setState({ usersList: _user })
    })
  }

  _addGroup(_title) {
    if (_title.length > 0) {
      addGroup(this.state.selectedUid, _title).then((res) => {
        console.log(res)
        getAllGroups(this.state.selectedUid).then((groupRes) => {
          this.setState({ groups: groupRes.response })
        })

      })
    }

  }

  _addToGroup(_did, _gid) {

    addDeviceToGroup(this.state.selectedUid, _did, _gid).then((res) => {
      console.log(res)
    })


  }


  _show(_uid) {

    this.setState({ loader: true });
    getBuildings(_uid).then((data) => {
      if (data.response) {
        if (data.response.length > 0) {

          data.response.map((item) => {
            getAllDevices(item.id).then((res) => {
              if (res.response) {
                console.log(res.response.data.devices, item.title)
                this.setState({ loader: false });
                if (res.success) {

                  res.response.data.devices.map((item2) => {
                    item2['title'] = item.title;
                  })

                  getAllGroupsDevices(_uid).then((resGD) => {
                    this.setState({ groupsDevices: resGD.response })
                  });


                  getAllGroups(_uid).then((groupRes) => {
                    this.setState({ selectedUid: _uid, groups: groupRes.response, deviceListArr: this.state.deviceListArr.concat(res.response.data.devices), showDevices: true })
                  })

                }
              } else {
                this.setState({ loader: false });
              }
            })
          })

        } else {
          this.setState({ showDevices: true, loader: false })
        }
      }
    })


  }

  render() {

    var list = this.state.usersList.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{item.id}</th>
          <td>{item.fio}</td>
          <td className="table-btn-td">
            <div className="table-btn-wrap">
              <button className="btn profile-icon-wrap" onClick={() => {
                this._show(item.id)
              }}>
                <img className="profile-icon" src={require("../../assets/img/ui-icons/settings-cog.png")} />
              </button>
            </div>
          </td>

        </tr>
      )
    });

    return (
      <div ref={this.divPage} id="page">
        <UiShowDevices
          show={this.state.showDevices}
          devices={this.state.deviceListArr}
          groups={this.state.groups}
          groupsDevices={this.state.groupsDevices}
          addGroup={(v) => this._addGroup(v)}
          addToGroup={(did, gid) => this._addToGroup(did, gid)}
          cancelPress={() => this.setState({ showDevices: false })}
        />
        <UiLoader show={this.state.loader} />
        <div className="wrapper">
          <UiHeader />
          <div className="content">
            <UiLeftMenu type={this.state.user.type} fio={this.state.user.fio} activePage={8} />

            <div className="container">

              <div className="card content-card mt-4">
                <div className="card-body" style={{ minHeight: this.state.contentHeight + 'px' }} >

                  <div className="body-inner">
                    <h2 className="body-title">Список Устройств <span>/ {this.state.roomTitle}</span></h2>
                    <UiBreadcrumb backPress="/" linksList={[{ value: 0, linkPress: "/alldevices", linkText: "Список устройств" }]} />
                    <div className="body-scroll">
                      <div className="card card-gray empty-card-2">
                        <p>Для добавления устройств воспользуйтесь панелью администрирования на плате управления RaZberry</p>
                      </div>
                      <div className="table-wrap">
                        <div className="table-scroll-wrap">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">id</th>
                                <th scope="col">Пользователь</th>
                                <th scope="col"> </th>
                              </tr>
                            </thead>
                            <tbody>
                              {list}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default windowSize(AllDevices);