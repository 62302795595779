import React, { Component } from 'react';
import windowSize from 'react-window-size';

import UiHeader from '../../components/ui/UiHeader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';
import UiDeviceCard from '../../components/ui/cards/UiDeviceCard.js';
import UiScriptSmallCard from '../../components/ui/cards/UiScriptSmallCard.js';
import UiRoomCard from '../../components/ui/cards/UiRoomCard.js';
import UiLoader from '../../components/ui/modals/UiLoader.js';
import UiAlert from '../../components/ui/modals/UiAlert.js';

import ENV from '../../services/Env.js'
import { getScripts, getAllDevices } from '../../services/Devices.js';
import { loadUser, getUser, getBuildings, getMessages, getMessagesList, addMessage } from '../../services/Users.js';
import { storeData, retrieveData } from '../../services/Storage';
import { getAllRooms, getAllPlans } from '../../services/Rooms.js';
import UiBreadcrumb from '../../components/ui/UiBreadcrumb.js';

const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);


class Support extends Component {

  state = {
    user: {
      adress: "",
      city: {
        title: ""
      }
    },
    contentHeight: this.props.windowHeight - 130,

    messages: [],
    oldMessages: [],
    messageList: [],
    selectedMessagesList: [],
    showAllMessages: true,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();
  }

  _getTitleBuilding(_id) {
    let title = "";
    this.state.buildingsList.map((item) => {
      if (_id = item.id) title = item.title;
    })
    return title;
  }

  _load() {
    let _user = loadUser();

    if (_user == null) {
      this.props.history.push("/login")
    } else {
      this.setState({ user: _user, loader: false });
    }
    this.setState({loader: true});
    getMessagesList().then((res) => {
      this.setState({ loader: false, messageList: res.response })
      console.log(res)
    })

   
  }

  _difDate(_date) {
    var someDate = new Date();
    var numberOfDaysToAdd = 6;
    someDate.setDate(someDate.getDate() - numberOfDaysToAdd);
    return (new Date(_date) > someDate)
  }

  _show(_id) {
    let _arr = [];
    this.setState({ loader: true })
    getMessages(_id).then((res) => {
   
      this.setState({ loader: false, selectedUserId: _id, selectedMessagesList: res.response, showAllMessages: false });
      console.log(res)
    })
    
  }

  _add(_text){
    addMessage( this.state.selectedUserId, 0, _text).then((res)=>{
      this._show(this.state.selectedUserId);
    })
  }



  render() {
    var messagesListOpen = this.state.messageList.map((item, index) => {
      if (this._difDate(item.message.created_at)) {
        return (
          <div className="mail-item" key={index} onClick={() => this._show(item.user.id)}>
            <h3>{item.user.fio}</h3>
            <p>{item.message.created_at}</p>
          </div>
        )
      }
    });
    var messagesClosetList = this.state.messageList.map((item, index) => {
      if (!this._difDate(item.message.created_at)) {
        return (
          <div className="mail-item" key={index}>
            <h3>{item.user.fio}</h3>
            <p>{item.message.created_at}</p>
          </div>
        )
      }
    });

    var messages = this.state.selectedMessagesList.map((item, index) => {
      if (item.receive_id != 0) {
        return (
          <div className="msg msg-user" key={index}>
            <p className="msg-text">{item.text}</p>
            <p className="msg-info">{item.created_at}</p>
          </div>

        )
      } else {
        return (
          <div className="msg " key={index}>
            <p className="msg-text">{item.text}</p>
            <p className="msg-info">{item.created_at}</p>
          </div>
        )
      }
    });

    return (
      <div ref={this.divPage} id="page">
        <UiLoader show={this.state.loader} />
        <div className="wrapper">
          <UiHeader />
          <div className="content">
            <UiLeftMenu type={this.state.user.type} fio={this.state.user.fio} activePage={6} />
            <div className="container">
              <div className="card content-card mt-4">
                <div className="card-body" style={{ minHeight: this.state.contentHeight + 'px' }} >
                  <div className="body-inner no-breadcrumb">
                    <h2 className="body-title">Сообщения Пользователей <span>/ {this.state.buildingTitle ? this.state.buildingTitle : "Администратор"}</span></h2>
                    <UiBreadcrumb backPress="/" linksList={[{ value: 0, linkPress: "/support", linkText: "Сообщения пользователей" }]} />
                    <div className="body-scroll">

                      {this.state.showAllMessages ?
                        <div className="mail-list">
                          <h3 className="list-title title-new">Новые сообщения</h3>
                          {messagesListOpen}
                          <h3 className="list-title">Старые чаты</h3>
                          {messagesClosetList}
                        </div>
                        :
                        <div className="mail-chat">
                          <h3>Чат с пользователем №123456</h3>
                          <div className="mail-chat-form">
                            <p>Написать сообщение:</p>
                            <textarea className="form-control" rows="2" onChange={(res) => { this.setState({text: res.target.value }) }} ></textarea>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <button className="btn btn-info btn-block mb-2" onClick={()=> this._add(this.state.text) }>Отправить</button>
                            </div>
                            <div className="col-md-4 offset-md-5">
                              <button className="btn btn-secondary btn-block mb-2" onClick={()=> this.setState({showAllMessages: true}) }>Закрыть чат</button>
                            </div>
                          </div>
                          <div className="mail-chat-msg">
                            {messages}
                          </div>
                        </div>
                      }



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default windowSize(Support);