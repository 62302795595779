import React, { Component } from 'react';
import windowSize from 'react-window-size';

import UiHeader from '../../components/ui/UiHeader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';
import UiEditChild from '../../components/ui/modals/UiEditChild.js';
import UiLoader from '../../components/ui/modals/UiLoader.js';

import ENV from '../../services/Env.js'
import { getAllDevices } from '../../services/Devices.js';
import { storeData, retrieveData } from '../../services/Storage';
import { loadUser, getUser, addChildUser, getChildUsers, removeChildUser } from '../../services/Users.js';
import { devicesChildsList, removeChildDevice, addChildDevice } from '../../services/Childs.js';

const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);

class Parent extends Component {

  state = {
    isMenuOpen: false,
    isAccauntOpen: false,
    isInfoOpen: false,
    isAlertShow: false,
    isAddCart: false,
    loader: false,
    isEditShow: false,

    user: {
      adress: "",
      city: {
        title: ""
      }
    },

    selectedChildId: 0,

    devicesList: [],
    selectedChildDevices: [],
    childsList: [],
    selectedFile: null,
    userTitle: "",

    contentHeight: this.props.windowHeight - 130,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let _user = loadUser();
    this.setState({ user: _user }, () => this._load())
  }

  _load() {
    let _user = loadUser();
    global.selectedBuildingId = retrieveData('selectedBuildingId');
    this.setState({ loader: true, user: _user, selectedBuildingId: retrieveData('selectedBuildingId') });

    getAllDevices(global.selectedBuildingId).then((res) => {
      console.log("devicesList", res);
      if (res.response) {

        if (res.success) {
          this.setState({ devicesList: res.response.data.devices, loader: false });
        }
      } else {
        this.setState({ loader: false });
      }

    })

    getChildUsers(_user.id).then((res) => {
      this.setState({ childsList: res.response })
      console.log(res);
    })
  }


  _remove(id) {
    removeChildUser(id).then((res) => {
      console.log(res);
      this._load();
    })
  }

  _removeDevice(id) {
    removeChildDevice(id).then((res) => {
      console.log(res);
      devicesChildsList(this.state.selectedChildId).then((res) => {
        this.setState({ selectedChildDevices: res.response });
      });
    })
  }

  showAlert(title, type) {
    this.setState({ isAlertShow: true, alertText: title, alertType: type });
  }

  showEditUser(_id, _title) {
    devicesChildsList(_id).then((res) => {
      console.log("devicesChildsList", res)
      this.setState({ isEditShow: true, selectedChildId: _id, selectedChildDevices: res.response, selectedUserName: _title });
    });

  }

  _addChildDevice(did) {

    addChildDevice(this.state.selectedChildId, did).then((data) => {
      devicesChildsList(this.state.selectedChildId).then((res) => {
        this.setState({ selectedChildDevices: res.response });
      });
    })
  }

  _addUser() {
    if (this.state.userTitle.length > 2) {
      addChildUser(this.state.user.id, this.state.userTitle).then((res) => {
        console.log(res);
        this._load();
      })
    }

  }

  render() {
    var tablesList = this.state.childsList.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{item.title}</td>
          <td>{item.login}</td>
          <td>{item.password}</td>
          <td className="table-btn-td">
            <div className="table-btn-wrap">
              <button className="btn profile-icon-wrap" onClick={() => this.showEditUser(item.id, item.login)}>
                <img className="profile-icon" src={require("../../assets/img/ui-icons/settings-cog.png")} />
              </button>
            </div>
          </td>
          <td className="table-btn-td">
            <div className="table-btn-wrap">
              <button className="btn profile-icon-wrap" onClick={() => this._remove(item.id)}>
                <img className="profile-icon" src={require("../../assets/img/ui-icons/trash.png")} />
              </button>
            </div>
          </td>
        </tr>
      )
    })

    return (
      <div ref={this.divPage} id="page">
        <UiLoader show={this.state.loader} />
        <UiEditChild
          show={this.state.isEditShow}
          devicesChildList={this.state.selectedChildDevices}
          devicesList={this.state.devicesList}
          userTitle={this.state.selectedUserName}
          addDevice={(_did) => this._addChildDevice(_did)}
          cancelPress={() => this.setState({ isEditShow: false })}
          removeDevice={(val) => this._removeDevice(val)}
        />
        <div className="wrapper">
          <UiHeader />
          <div className="content">
            <UiLeftMenu type={this.state.user.type} fio={this.state.user.fio} activePage={5} />

            <div className="container">

              <div className="card content-card mt-4">
                <div className="card-body" style={{ minHeight: this.state.contentHeight + 'px' }}>
                  <div className="body-inner">
                    <h2 className="body-title">Родительский Контроль </h2>
                    <div className="body-scroll">

                      <div className="card card-gray form">
                        <h3 className="form-title">Добавить пользователя</h3>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Имя пользователя" onChange={(e) => this.setState({ userTitle: e.target.value })} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <button type="button" className="btn btn-outline-warning btn-block" onClick={() => this._addUser()}>Добавить</button>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <p>*Логин и пароль пользователя будут созданны автоматически.</p>
                          </div>
                        </div>
                      </div>

                      {tablesList.length > 0 ?
                        <div className="links-block">
                          <div className="info-block">
                            <div className="form form-no-indent table-form">
                              <h2 className="form-title">Список пользователей</h2>
                              <table className="table table-bordered">
                                <thead>
                                  <tr className="table-info">
                                    <th scope="col">#</th>
                                    <th scope="col">Имя</th>
                                    <th scope="col">Логин</th>
                                    <th scope="col">Пароль</th>

                                    <th scope="col" className="text-center">Добавить</th>
                                    <th scope="col" className="text-center">Удалить</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tablesList}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default windowSize(Parent);