import ENV from './Env.js';
import AsyncStorage from "react";

 
export const getAllDevices= (_uid) => {
  return fetch(ENV.API_URL+'/devices/list/'+_uid, {
      method: 'GET'
    }).then((response) => response.json());
}

export const onDevice= (_uid, _did) => {
  return fetch(ENV.API_URL+'/devices/control/'+_uid+'/'+_did+"/on", {
      method: 'GET'
    }).then((response) => response.json());
}

export const offDevice= (_uid, _did) => {
  return fetch(ENV.API_URL+'/devices/control/'+_uid+'/'+_did+"/off", {
      method: 'GET'
    }).then((response) => response.json());
}

export const changeMetric = (_uid, _did, _metric) => {
  console.log({
    building: _uid,
    device: _did,
    metric: _metric
  });
  return fetch(ENV.API_URL+'/devices/metric/control', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        building: _uid,
        device: _did,
        metric: _metric
      }) 
    }).then(function(response) {
      return response.json();
    });
}


export const getScripts = (_uid) => {

  return fetch(ENV.API_URL+'/scripts/list/all', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: _uid,
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const addScript = (_data) => {

  return fetch(ENV.API_URL+'/scripts/list/add', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(_data) 
    }).then(function(response) {
      return response.json();
    });
}
 
export const updateScript = (_data) => {

  return fetch(ENV.API_URL+'/scripts/list/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(_data) 
    }).then(function(response) {
      return response.json();
    });
}

export const deleteScript = (_data) => {

  return fetch(ENV.API_URL+'/scripts/list/delete', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(_data) 
    }).then(function(response) {
      return response.json();
    });
}


export const getAllGroupsDevices = (_uid) => {
  return fetch(ENV.API_URL+'/devices/groups/by/user/'+_uid, {
      method: 'GET'
    }).then((response) => response.json());
}

export const addDeviceToGroup = (_uid, _did, _gid) => {
  console.log({
    uid: _uid,
    did: _did,
    gid: _gid
  });
  return fetch(ENV.API_URL+'/devices/groups/to/group', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: _uid,
        did: _did,
        gid: _gid
      }) 
    }).then(function(response) {
      return response.json();
    });
}


export const getAllGroups = (_uid) => {
  return fetch(ENV.API_URL+'/devices/groups/all/'+_uid, {
      method: 'GET'
    }).then((response) => response.json());
}

export const addGroup = (_uid, _title) => {
  return fetch(ENV.API_URL+'/devices/groups/add', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: _uid,
        title: _title
      }) 
    }).then(function(response) {
      return response.json();
    });
}
 