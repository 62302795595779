import React, { Component } from 'react';

import UiHeader from '../../components/ui/UiHeader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';

import ENV from '../../services/Env.js'
import { getAllDevices } from '../../services/Devices.js';
import { loadUser, getUser } from '../../services/Users.js';
import UiBreadcrumb from '../../components/ui/UiBreadcrumb.js';

const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);


class Setting extends Component {

  state = {
    isMenuOpen: false,
    isAccauntOpen: false,
    isInfoOpen: false,
    isAlertShow: false,
    isAddCart: false,
    loader: false,

    filesList: [],
    selectedFile: null,
    selectedFileName: "",
    selectedFileTypeTitle: "Выберите тип файла",
    selectedFileType: 0,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();
  }

  _load() {
    let _user = loadUser();
    /*if (_user == null) {
      this.props.history.push("/login")
    }*/

  }


  showAlert(title, type) {
    this.setState({ isAlertShow: true, alertText: title, alertType: type });
  }

  render() {
    return (
      <div ref={this.divPage} id="page">
        <div className="wrapper">
          <UiHeader />
          <div className="content">
            <UiLeftMenu type={this.state.user.type} fio={this.state.user.fio} />

            <div className="container">

              <div className="card content-card mt-4">
                <div className="card-body">
                  <div className="body-inner">
                    <h2 className="body-title">Настройки <span>/ ул. Малахова 146</span></h2>
                    <UiBreadcrumb backPress="/" linksList={[{ value: 0, linkPress: "/setting", linkText: "Настройки" }]} />
                    <div className="body-scroll">


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default Setting;