import React, { Component } from 'react';
import { useParams } from "react-router-dom";

import UiHeader from '../../components/ui/UiHeader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';
import UiRoomCard from '../../components/ui/cards/UiRoomCard.js';
import UiBreadcrumb from '../../components/ui/UiBreadcrumb.js';
import UiDeviceCard from '../../components/ui/cards/UiDeviceCard.js';
import UiScriptCard from '../../components/ui/cards/UiScriptCard.js';

import ENV from '../../services/Env.js'

import Editor from '../../components/class/editor/Editor.js';
import Selector from '../../components/class/editor/Selector.js';
import Source from '../../components/class/editor/Source.js';

import { getAllDevices } from '../../services/Devices.js';
import { loadUser, getUser } from '../../services/Users.js';
import { getAllRooms, addRoom, getAllPlans, editRoom, removeRoom } from '../../services/Rooms.js';

const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);


class Room extends Component {

  state = {
    isEditorBarOpen: false,
    isAccauntOpen: false,
    isInfoOpen: false,
    isAlertShow: false,
    isAddCart: false,
    loader: false,

    filesList: [],
    selectedFile: null,
    selectedFileName: "",
    selectedFileTypeTitle: "Выберите тип файла",
    selectedFileType: 0,

    devicesList: [],
    roomsList: [],
    userPlan: null,

    size: 10,
    type: 0,
    title: "",

    user: {
      adress: "",
      city: {
        title: ""
      }
    },

    isSelected: false,
    selectedHeight: 0,
    selectedWidth: 0,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let _user = loadUser();
    this.setState({ user: _user }, () => this._load())

  }

  _load() {

    console.log("_navigator", this.props.match.params.id);
    getAllRooms(this.state.user.id).then((res) => {
      console.log(res)
      this.setState({ roomsList: res.response });
      res.response.map((item) => {
        if (item.id == this.props.match.params.id) {
          this.setState({
            selectedRoom: item,
            roomTitle: item.title,
            roomSize: item.size,
            roomType: item.type,
            devicesList: item.devices
          })
        }
      });

    })

  }

  showAlert(title, type) {
    this.setState({ isAlertShow: true, alertText: title, alertType: type });
  }




  _update() {
    editRoom(this.state.user.id, this.state.roomTitle,  this.state.roomSize,  this.state.roomType).then((res)=>{
      console.log(res);
    })
  }

  _remove(){
    removeRoom(this.selectedRoom.id).then((res)=>{
      this.props.history.push("/rooms")
      console.log(res);
    })
  }

  render() {
    var rooms = this.state.roomsList.map((item, index) => {
      return (
        <UiRoomCard key={index} roomType={item.type} type={item.type} title={item.title} devices={item.devices.length} />
      )
    })

    var devices = this.state.devicesList.map((item, index) => {
      return (
        <UiDeviceCard
          key={index}
          deviceType="1"
          title="123"
          params="123"
          room="Кабинет"
          status={true}
          onPress={() => { }}
        />
      )
    })


    return (
      <div ref={this.divPage} id="page">
        <div className="wrapper">
          <UiHeader />
          <div className="content">
            <UiLeftMenu type={this.state.user.type} fio={this.state.user.fio} activePage={3} />

            <div className="container">

              <div className="card content-card mt-4">
                <div className="card-body">

                  <div className="body-inner">
                    <h2 className="body-title">Комната <span>/ {this.state.roomTitle}</span></h2>
                    <UiBreadcrumb backPress="/" linksList={[{ value: 0, linkPress: "/rooms", linkText: "Планировка дома" }, { value: 1, linkPress: "/room", linkText: "Редактирование комнаты" }]} />
                    <div className="body-scroll">


                      <div className="card card-gray form">
                        <h3 className="form-title">Изменить комнату</h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Название комнаты" defaultValue={this.state.roomTitle} onChange={(e) => this.setState({ roomTitle: e.target.value })} />
                            </div>
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Площадь" defaultValue={this.state.roomSize} onChange={(e) => this.setState({ roomSize: e.target.value })} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="form-control" defaultValue={this.state.roomType} onChange={(e) => this.setState({ roomType: e.target.value })}>

                                <option value="0" >Гостинная</option>
                                <option value="1" >Кухня</option>
                                <option value="2" >Спальня</option>
                                <option value="3" >Детская</option>

                                <option value="4" >Коридор</option>
                                <option value="5" >Ванная</option>
                                <option value="6" >Подсобка</option>
                                <option value="7" >Свободная</option>

                              </select>
                            </div>
                            <div className="form-group">
                              <button type="button" className="btn btn-outline-warning btn-block" onClick={() => this._update()}>Обновить</button>
                            </div>
                          </div>
                        </div>
                        <h3 className="form-title">Удалить комнату</h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <button type="button" className="btn btn-outline-warning btn-block" onClick={() => this._addRoom()}>Удалить комнату</button>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p>*Вы можете добавить устройство на странице устройств.</p>
                          </div>
                        </div>
                      </div>



                      <div className="links-block">
                        <div className="info-block">
                          

                          <div className="title-wrap link-title-wrap">
                            {this.state.devicesList.lengt > 0 ? <h3 className="info-block-title">Устройства в комнате</h3> : <h3 className="info-block-title">У вас нет устройств</h3>}
                          </div>
                          <div className="card-list">
                            {devices}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default Room;