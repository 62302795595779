import React, { Component } from 'react';
import { Container } from './components';

//import './libraries/bootstrap.js';
import './styles/bootstrap.css';
import './styles/style.scss';

class App extends Component {
  render() {
    return (
      <Container/>
    );
  }
}

export default App;
