import React, { Component } from 'react';
import windowSize from 'react-window-size';
import { useParams } from "react-router-dom";

import UiHeader from '../../components/ui/UiHeader.js';
import UiLoader from '../../components/ui/modals/UiLoader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';
import UiRoomCard from '../../components/ui/cards/UiRoomCard.js';
import UiBreadcrumb from '../../components/ui/UiBreadcrumb.js';
import UiDeviceCard from '../../components/ui/cards/UiDeviceCard.js';
import UiScriptCard from '../../components/ui/cards/UiScriptCard.js';

import ENV from '../../services/Env.js'

import { getAllDevices } from '../../services/Devices.js';
import { loadUser, getUsers, getAllBuildings, removeBuilding, addBuilding, updateBuilding } from '../../services/Users.js';
import { getAllRooms, addRoom, getAllPlans, savePlan } from '../../services/Rooms.js';
import UiEditUser from '../../components/ui/modals/UiEditUser.js';
import UiEditHome from '../../components/ui/modals/UiEditHome.js';

const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);


class Houses extends Component {

  state = {
    user: {
      adress: "",
      city: {
        title: ""
      }
    },

    uid: 0,
    title: "",
    zlogin: "",
    zpass: "",
    zsesid: "",

    buildingList: [],
    usersList: [],

    editHomeVisible: false,
    contentHeight: this.props.windowHeight - 130,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let _user = loadUser();
    this.setState({ user: _user }, () => this._load())
  }

  _load() {
    //console.log("_navigator", this.props.match.params.id);
    this.setState({ loader: true });
    getAllBuildings().then((res) => {
      console.log(res)
      this.setState({ buildingList: res.response, loader: false })
    })

    getUsers().then((res) => {
      console.log(res)
      let _arr = [];
      res.response.map((item) => {
        if (item.type == 2) _arr.push(item)
      })
      this.setState({ usersList: _arr, uid: _arr[0].id })
    })
  }

  _remove(_id) {
    this.setState({ loader: true });
    removeBuilding(_id).then((res) => {
      console.log(res)
      this._load();
      this.setState({ loader: false })
    })
  }

  _add() {
    console.log(this.state.title, this.state.zlogin, this.state.zpass, this.state.zsesid);
    if (this.state.title.length > 0 && this.state.zlogin.length > 0 && this.state.zpass.length > 0 && this.state.zsesid.length > 0) {
      addBuilding(this.state.uid, this.state.title, this.state.zlogin, this.state.zpass, this.state.zsesid).then((res) => {
        console.log(res);
        this._load();
      })
    } else {
      alert("Заполните все поля");
    }
  }

  _update(_id, _uid, _title, _zlogin, _zpass, _zsesid) {
    updateBuilding(_id, _uid, _title, _zlogin, _zpass, _zsesid).then((res) => {
      console.log(res);
      this._load();
    })
  }

  render() {
    var list = this.state.buildingList.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{item.building.id}</th>
          <td>{item.building.zlogin}</td>
          <td>{item.building.zpass}</td>
          <td>{item.building.title}</td>
          <td>{item.user[0].fio}</td>
          <td className="table-btn-td">
            <div className="table-btn-wrap">
              <button className="btn profile-icon-wrap" onClick={() => {
                this.setState({
                  selectedObj: item,
                  editHomeVisible: true
                });
              }}>
                <img className="profile-icon" src={require("../../assets/img/ui-icons/settings-cog.png")} />
              </button>
            </div>
          </td>
          <td className="table-btn-td">
            <div className="table-btn-wrap">
              {this.state.user.type == 0 ?
                <button className="btn profile-icon-wrap" onClick={() => {
                  this._remove(item.building.id);
                }}>
                  <img className="profile-icon" src={require("../../assets/img/ui-icons/trash.png")} />
                </button>
                : null}
            </div>
          </td>
        </tr>
      )
    });

    var users = this.state.usersList.map((item, index) => {
      return (
        <option value={item.id} key={index} >{item.fio}</option>
      )
    });

    return (
      <div ref={this.divPage} id="page">
        <UiLoader show={this.state.loader} />
        <UiEditHome
          show={this.state.editHomeVisible}
          selected={this.state.selectedObj}
          usersList={this.state.usersList}
          closeCallBack={() => this.setState({ editHomeVisible: false })}
          removeCallBack={() => this._remove(this.state.selectedObj.building.id)}
          saveCallBack={(_id, _uid, _title, _zlogin, _zpass, _zsesid) => {
            this.setState({ editHomeVisible: false });
            this._update(_id, _uid, _title, _zlogin, _zpass, _zsesid);
          }}
        />

        <div className="wrapper">
          <UiHeader />
          <div className="content">
            <UiLeftMenu type={this.state.user.type} fio={this.state.user.fio} activePage={9} />

            <div className="container">

              <div className="card content-card mt-4">
                <div className="card-body" style={{ minHeight: this.state.contentHeight + 'px' }} >

                  <div className="body-inner">
                    <h2 className="body-title">Список Помещений </h2>
                    <UiBreadcrumb backPress="/" linksList={[{ value: 0, linkPress: "/houses", linkText: "Список помещений" }]} />
                    <div className="body-scroll">
                      <div className="table-wrap">
                        <div className="row">

                          <div className="col-md-6">
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Название" onChange={(res) => { this.setState({ title: res.target.value }) }} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Z-LOGIN" onChange={(res) => { this.setState({ zlogin: res.target.value }) }} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Z-ID" onChange={(res) => { this.setState({ zsesid: res.target.value }) }} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Z-PASS" onChange={(res) => { this.setState({ zpass: res.target.value }) }} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="form-control" onChange={(res) => { this.setState({ uid: res.target.value }) }} >
                                {users}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            {this.state.user.type == 0 ? <button className="btn btn-info btn-block mb-3" onClick={() => this._add()} >Добавить помещение</button> : null}
                          </div>
                        </div>
                        <div className="table-scroll-wrap">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">id</th>
                                <th scope="col">Login</th>
                                <th scope="col">Pass</th>
                                <th scope="col">Название</th>
                                <th scope="col">Пользователь</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {list}
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default windowSize(Houses);