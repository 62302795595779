import React, { Component } from 'react';

class UiEditUser extends Component {

    state = {
        userType: 0,
        userFio: "",
        userCity: 0,
    }

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) { 
        if(this.props.show && this.state.userType == "" ){
            let _arr = [];
            this.setState({
                userType: this.props.userType,
                userFio: this.props.userFio,

            })
        }
    }

    render() {
        var citys = this.props.citysList.map((item, index) => {
            return (
              <option value={item.id} key={index}>{item.title}</option>
            )
          })
      
        return (
            <div className={"alert-wrap " + (this.props.show ? "show" : "hide")}>
                <div className="card modal-card">
                    <div className="modal-card-inner">

                        <h3 className="form-title">Редактирование пользователя</h3>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <select className="form-control" value={this.state.userType} onChange={(res) => { this.setState({userType : res.target.value}) }}  >
                                        <option value="0" >Администратор</option>
                                        <option value="1" >Менеджер</option>
                                        <option value="2" >Пользователь</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="ФИО" value={this.state.userFio} onChange={(res) => { this.setState({userFio : res.target.value}) }} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <select className="form-control" onChange={(res) => { this.setState({userCity : res.target.value}) }}>
                                       {citys}
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="button" className="btn btn-info btn-block mb-2" onClick={() => this.props.updateCallBack( this.state.userFio, this.state.userType, this.state.userCity ) } >Сохранить</button>
                        </div>
                        <div className="col-md-4">
                            <button type="button" className="btn btn-secondary btn-block mb-2" onClick={() => this.props.deleteCallBack()}>Удалить</button>
                        </div>
                        <div className="col-md-4">
                            <button type="button" className="btn btn-outline-info btn-block" onClick={() => this.props.closeCallBack()  }>Отмена</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UiEditUser;