import React, { Component } from 'react';

class UiNotification extends Component {

  state = {
    switchActive: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <a href="" onClick={(e) => this.props.onPress(e)} className="btn card notification-card">
        <div className="notification-text-wrap">
          {this.props.deviceType == "battery" ?
            <div className="notification-icon-wrap">
              <img className="notification-icon" src={require("../../../assets/img/device/battery.svg")} />
              <img className="notification-icon-active" src={require("../../../assets/img/device/battery-white.svg")} />
            </div>
            :
            (this.props.deviceType == "switchRGB" ?
              <div className="notification-icon-wrap">
                <img className="notification-icon" src={require("../../../assets/img/device/light.svg")} />
                <img className="notification-icon-active" src={require("../../../assets/img/device/light-white.svg")} />
              </div>
              :
              (this.props.deviceType == "switchRGBW" ?
                <div className="notification-icon-wrap">
                  <img className="notification-icon" src={require("../../../assets/img/device/light.svg")} />
                  <img className="notification-icon-active" src={require("../../../assets/img/device/light-white.svg")} />
                </div>
                :
                (this.props.deviceType == "switchControl" ?
                  <div className="notification-icon-wrap">
                    <img className="notification-icon" src={require("../../../assets/img/device/switch.svg")} />
                    <img className="notification-icon-active" src={require("../../../assets/img/device/switch-white.svg")} />
                  </div>
                  :
                  (this.props.deviceType == "sensorMultilevel" ?
                    <div className="notification-icon-wrap">
                      <img className="notification-icon" src={require("../../../assets/img/device/sensor.svg")} />
                      <img className="notification-icon-active" src={require("../../../assets/img/device/sensor-white.svg")} />
                    </div>
                    :
                    (this.props.deviceType == "sensorMultiline" ?
                      <div className="notification-icon-wrap">
                        <img className="notification-icon" src={require("../../../assets/img/device/sensor.svg")} />
                        <img className="notification-icon-active" src={require("../../../assets/img/device/sensor-white.svg")} />
                      </div>
                      :
                      (this.props.deviceType == "camera" ?
                        <div className="notification-icon-wrap">
                          <img className="notification-icon" src={require("../../../assets/img/device/cctv.svg")} />
                          <img className="notification-icon-active" src={require("../../../assets/img/device/cctv-white.svg")} />
                        </div>
                        :
                        (this.props.deviceType == "doorlock" ?
                          <div className="notification-icon-wrap">
                            <img className="notification-icon" src={require("../../../assets/img/device/protection.svg")} />
                            <img className="notification-icon-active" src={require("../../../assets/img/device/protection-white.svg")} />
                          </div>
                          :
                          (this.props.deviceType == "thermostat" ?
                            <div className="notification-icon-wrap">
                              <img className="notification-icon" src={require("../../../assets/img/device/temperature.svg")} />
                              <img className="notification-icon-active" src={require("../../../assets/img/device/temperature-white.svg")} />
                            </div>
                            :
                            (this.props.deviceType == "switchBinaryThermostat" ?
                              <div className="notification-icon-wrap">
                                <img className="notification-icon" src={require("../../../assets/img/device/temperature.svg")} />
                                <img className="notification-icon-active" src={require("../../../assets/img/device/temperature-white.svg")} />
                              </div>
                              :
                              (this.props.deviceType == "switchBinary" ?
                                <div className="notification-icon-wrap">
                                  <img className="notification-icon" src={require("../../../assets/img/device/switch.svg")} />
                                  <img className="notification-icon-active" src={require("../../../assets/img/device/switch-white.svg")} />
                                </div>
                                :
                                (this.props.deviceType == "sensorBinary" ?
                                  <div className="notification-icon-wrap">
                                    <img className="notification-icon" src={require("../../../assets/img/device/sensor.svg")} />
                                    <img className="notification-icon-active" src={require("../../../assets/img/device/sensor-white.svg")} />
                                  </div>
                                  :
                                  (this.props.deviceType == "switchMultilevel" ?
                                    <div className="notification-icon-wrap">
                                      <img className="notification-icon" src={require("../../../assets/img/device/console.svg")} />
                                      <img className="notification-icon-active" src={require("../../../assets/img/device/console-white.svg")} />
                                    </div>
                                    :
                                    (this.props.deviceType == "switchMultilevelBlinds" ?
                                      <div className="notification-icon-wrap">
                                        <img className="notification-icon" src={require("../../../assets/img/device/console.svg")} />
                                        <img className="notification-icon-active" src={require("../../../assets/img/device/console-white.svg")} />
                                      </div>
                                      :
                                      (this.props.deviceType == "toggleButton" ?
                                        <div className="notification-icon-wrap">
                                          <img className="notification-icon" src={require("../../../assets/img/device/switch.svg")} />
                                          <img className="notification-icon-active" src={require("../../../assets/img/device/switch-white.svg")} />
                                        </div>
                                        :
                                        (this.props.deviceType == "text" ?
                                          <div className="notification-icon-wrap">
                                            <img className="notification-icon" src={require("../../../assets/img/device/smart-app.svg")} />
                                            <img className="notification-icon-active" src={require("../../../assets/img/device/smart-app-white.svg")} />
                                          </div>
                                          :
                                          <div className="notification-icon-wrap">
                                            <img className="notification-icon" src={require("../../../assets/img/device/smarthouse.svg")} />
                                            <img className="notification-icon-active" src={require("../../../assets/img/device/smarthouse-white.svg")} />
                                          </div>
                                        )
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          }
          <h3 className="notification-card-title">{this.props.title}</h3>
          <p className="notification-card-text">{this.props.text}</p>
        </div>
        <p className="notification-card-time"><strong>{this.props.time}</strong></p>
      </a>
    );
  }
}

export default UiNotification;