import React, { Component } from 'react';

class UiVideoCard extends Component {

  state = {
    switchActive: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <a href="" onClick={(e) => this.props.onPress(e)} className="btn card device-card">
        <div className="device-card-icon-wrap">
          <img className="device-card-icon" src={require("../../../assets/img/device/cctv.svg")} />
          <img className="device-card-icon-active" src={require("../../../assets/img/device/cctv-white.svg")} />
        </div>

        <h3 className="device-card-title">{this.props.title}</h3>
        <p className="device-card-text">{this.props.params}</p>
        <p className="device-card-text"><span>{this.props.room}</span></p>
      </a>
    );
  }

}

export default UiVideoCard;