import ENV from './Env.js';


export const devicesChildsList = (_uid) => {
    return fetch(ENV.API_URL + '/users/childs/devices/list', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: _uid,
        })
    }).then(function (response) {
        return response.json();
    });
}

export const removeChildDevice = (_uid) => {
    return fetch(ENV.API_URL + '/users/childs/devices/remove', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            cid: _uid,
        })
    }).then(function (response) {
        return response.json();
    });
}

export const addChildDevice = (_cid, _did) => {
    return fetch(ENV.API_URL + '/users/childs/devices/add', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            cid: _cid,
            did: _did
        })
    }).then(function (response) {
        return response.json();
    });
}