class Selector {

	constructor(stage, container, callback) {

		this.selected = null;
		this.callback = callback;
		this.selectedHeight = null;
		this.selectedWidth = null;

		var selection = document.createElement('span');
		selection.style.position = 'absolute';
		selection.style.display = 'block';
		selection.style.outline = 'solid 2px #99f';
		selection.style.pointerEvents = 'none';
		selection.setAttribute("class", "selection");
		document.body.appendChild(selection);
		//

		var selected = null;
		var dragged = false;
		var offset = { x: 0, y: 0 };
		var scale = 1;

		function updateSelection(element) {
			if (element.isSameNode(stage)) {
				selection.style.display = 'none';
				return;
			}
			console.log("ss", element, container.getBoundingClientRect().top - stage.getBoundingClientRect().top )
			var rect = element.getBoundingClientRect();
			
			var bodyRect = document.body.getBoundingClientRect(),
			elemRect = element.getBoundingClientRect(),
			offset   = elemRect.top - bodyRect.top;
	
			selection.style.left = rect.left + 'px';
			selection.style.top = (offset  ) + 'px';
			selection.style.width = rect.width + 'px';
			selection.style.height = rect.height + 'px';
			selection.style.display = 'block';
		}

		function addOnWheel(elem, handler) {
			if (elem.addEventListener) {
				if ('onwheel' in document) {
					// IE9+, FF17+
					elem.addEventListener("wheel", handler);
				} else if ('onmousewheel' in document) {
					// устаревший вариант события
					elem.addEventListener("mousewheel", handler);
				} else {
					// 3.5 <= Firefox < 17, более старое событие DOMMouseScroll пропустим
					elem.addEventListener("MozMousePixelScroll", handler);
				}
			} else { // IE8-
				elem.attachEvent("onmousewheel", handler);
			}
		}


		function collectionHas(a, b) { //helper function (see below)
			for (var i = 0, len = a.length; i < len; i++) {
				if (a[i] == b) return true;
			}
			return false;
		}
		function findParentBySelector(elm, selector) {
			var all = document.querySelectorAll(selector);
			var cur = elm.parentNode;
			while (cur && !collectionHas(all, cur)) { //keep going up until you find a match
				cur = cur.parentNode; //go up
			}
			return cur; //will return null if not found
		}

		//

		stage.addEventListener('click', (event) => {
			var target = event.target;
			if (findParentBySelector(event.target, "svg")) {
				if (findParentBySelector(event.target, "svg").classList.contains('obj')) target = findParentBySelector(event.target, "svg");
			}
			updateSelection(target);
		});
		/*
		stage.addEventListener( 'mouseover', function ( event ) {

			var target = event.target;
			updateSelection( target );

		} );*/

		stage.addEventListener('mousedown', (event) => {
			var target = event.target;
			if (findParentBySelector(event.target, "svg")) {
				if (findParentBySelector(event.target, "svg").classList.contains('obj')) target = findParentBySelector(event.target, "svg");
			}
			if (target.isSameNode(stage) === false) {
				if (target.tagName === 'circle') {

					offset.x = parseFloat(target.getAttribute('cx')) - event.clientX;
					offset.y = parseFloat(target.getAttribute('cy')) - event.clientY;

				} else {
					offset.x = parseFloat(target.getAttribute('x')) - event.clientX;
					offset.y = parseFloat(target.getAttribute('y')) - event.clientY;
				}

				selected = target;
				this.selected = target;
				this.selectedWidth = target.getAttribute('width');

			} else {
				this.selected = null;
				selection.style.display = 'none';
				// calculate event X, Y coordinates
				this.offsetX = event.clientX;
				this.offsetY = event.clientY;
				// assign default values for top and left properties
				if (!stage.style.left) { stage.style.left = '0px' };
				if (!stage.style.top) { stage.style.top = '0px' };
				this.coordX = parseInt(stage.style.left);
				this.coordY = parseInt(stage.style.top);
				dragged = true;
			}

			callback(target);
		});

		stage.addEventListener('mouseup', (event) => {
			selected = null;
			dragged = false;
		});

		stage.addEventListener('mousemove', (event) => {
			let cx = event.clientX + offset.x;
			let cy = event.clientY + offset.y;

			/*
				if( ( sx + 5 > event.clientX && sx < event.clientX + 5 )  &&
					( sy + 5 > event.clientY && sy <  event.clientY + 5 )  ){
						selected.setAttribute( 'height', 400 - event.clientY );
				} 
			*/

			if (selected) {

				if (selected.tagName === 'circle') {

					selected.setAttribute('cx', event.clientX + offset.x);
					selected.setAttribute('cy', event.clientY + offset.y);

				} else {

					selected.setAttribute('x', event.clientX + offset.x);
					selected.setAttribute('y', event.clientY + offset.y);

				}

				updateSelection(selected);

			}

			if (dragged) {
				stage.style.left = this.coordX + event.clientX - this.offsetX + 'px';
				stage.style.top = this.coordY + event.clientY - this.offsetY + 'px';
				stage.style.position = "absolute";
			}



		});

		addOnWheel(container, function (e) {
			selection.style.display = 'none';
			var delta = e.deltaY || e.detail || e.wheelDelta;

			// отмасштабируем при помощи CSS
			if (delta > 0) scale += 0.05;
			else scale -= 0.05;

			stage.style.transform = stage.style.WebkitTransform = stage.style.MsTransform = 'scale(' + scale + ')';

			// отменим прокрутку
			e.preventDefault();
		});


	}


	changeSize(type, val) {
		if (this.selected) {
			this.callback(this.selected);
			if (type == "height") this.selected.setAttribute('height', parseInt(this.selected.getAttribute('height')) + val);
			if (type == "width") this.selected.setAttribute('width', parseInt(this.selected.getAttribute('width')) + val);
		}

	}

	rotate(val) {
		if (this.selected) {

			this.selected.setAttribute("transform", "rotate(" + val + ")");

		}

	}

	remove() {
		if (this.selected) {
			document.getElementsByClassName("selection")[0].style.display = "none";
			this.selected.remove();
			this.selected = null;
			this.callback(this.selected);
		}

	}

}

export default Selector;