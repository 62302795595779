import React, { Component } from 'react';
import windowSize from 'react-window-size';

import UiAlert from '../../components/ui/modals/UiAlert.js';
import UiHeader from '../../components/ui/UiHeader.js';
import UiLoader from '../../components/ui/modals/UiLoader.js';
import UiLeftMenu from '../../components/ui/UiLeftMenu.js';
import UiBreadcrumb from '../../components/ui/UiBreadcrumb.js';
import UiEventCard from '../../components/ui/cards/UiEventCard.js';
import UiStatisticsCard from '../../components/ui/cards/UiStatisticsCard.js';
import UiVideoCard from '../../components/ui/cards/UiVideoCard.js';

import ENV from '../../services/Env.js';
import { storeData, retrieveData } from '../../services/Storage';
import { loadUser, getCameras, getUser, getBuildings } from '../../services/Users.js';
import { getAllDevices, getScripts, addScript, updateScript, deleteScript } from '../../services/Devices.js';

class Video extends Component {

  state = {
    loader: false,
    contentHeight: this.props.windowHeight - 130,

    selectedRTSP: null,
    camerasList: [],
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();
  }

  showAlert(title, type) {
    this.setState({ isAlertShow: true, alertText: title, alertType: type });
  }

  _load() {
    let _user = loadUser();
    this.setState({ loader: false, user: _user });

    getCameras(_user.id).then((res) => {
      console.log(res);
      this.setState({ camerasList: res.response });
    })

  }

  render() {

    var list = this.state.camerasList.map((item, index) => {
      return (
        <UiVideoCard key={index} title={item.title} params="вкл" onPress={() =>  window.open("http://devkot.ru/convert/index.php?get=" + item.link , "_blank") } />
      )
    })

    return (
      <div ref={this.divPage} id="page">
        <UiLoader show={this.state.loader} />

        <div className="wrapper">
          <UiHeader />
          <div className="content">
            <UiLeftMenu type="2" activePage={10} />

            <div className="container">

              <div className="card content-card mt-4">
                <div className="card-body" style={{ minHeight: this.state.contentHeight + 'px' }}>
                  <div className="body-inner">
                    <h2 className="body-title">Видеонаблюдение</h2>
                    <UiBreadcrumb backPress="/" linksList={[{ value: 0, linkPress: "/video", linkText: "Видеонаблюдение" }]} />
                    <div className="body-scroll">

                      <div className="card card-gray">
                        <div className="row">
                          <div className="col-6">
                            <UiStatisticsCard title={this.state.camerasList.length} text="Всего камер" />
                          </div>
                          <div className="col-6">
                           
                          </div>
                        </div>
                      </div>

                      <div className="links-block">
                        <div className="info-block">
                          <div className="card-list card-list-lines device-cards">
                            {list}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UiAlert
          show={this.state.isAlertShow}
          callBack={() => this.setState({ isAlertShow: false })}
          title="Внимание!"
          text={this.state.alertText}
          leftBtnText="Ok"
        />
      </div>

    );
  }
}

export default windowSize(Video);