import ENV from './Env.js';


export const saveUser = (_user) => {
   localStorage.setItem("mh_user", JSON.stringify(_user) );
}

export const loadUser = (_user) => {
  let user = localStorage.getItem("mh_user");
  if(user != null && user != undefined) user = JSON.parse(user); 
  return user;
}
 
export const loginUser = (_login, _pass) => {

  return fetch(ENV.API_URL+'/users/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        login: _login,
        password: _pass,
      }) 
    }).then(function(response) {
      return response.json();
    });
}


export const addUser = (_type, _fio,_city, _adress) => {
  return fetch(ENV.API_URL+'/users/add/user', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: _type,
        fio: _fio,
        city_id: _city,
        adress: _adress,
      
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const removeUser = (_id) => {
  return fetch(ENV.API_URL+'/users/delete', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: _id,      
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const updateUser = (_id, _fio, _type, _city ) => {
  return fetch(ENV.API_URL+'/users/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: _id,
        fio: _fio,
        type: _type,
        cityId: _city,
        adress: ""
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const addChildUser = (_uid, _title) => {
  return fetch(ENV.API_URL+'/users/add/child', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: _uid,
        name: _title,
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const removeChildUser = (_id) => {
  return fetch(ENV.API_URL+'/users/childs/remove', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: _id,
      }) 
    }).then(function(response) {
      return response.json();
    });
}


export const getChildUsers= (_uid) => {
  return fetch(ENV.API_URL+'/users/childs/id/'+_uid, {
      method: 'GET'
    }).then((response) => response.json());
}

export const getWeather = (_city) => {
  _city = _city.replace(/ /g, '');
  return fetch("http://api.openweathermap.org/data/2.5/weather?q="+_city+"&appid=cad25b0076aae4737cab2590ca464182&units=metric&lang=ru", {
      method: 'GET'
    }).then((response) => response.json());
}


export const removeBuilding = (_uid) => {
  return fetch(ENV.API_URL+'/users/del/building', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: _uid,
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const addBuilding = (_uid, _title, _login, _pass, _sesid) => {
  return fetch(ENV.API_URL+'/users/add/building', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: _uid,
        title: _title,
        login: _login,
        pass: _pass,
        sesid: _sesid,
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const updateBuilding = (_id, _uid, _title, _login, _pass, _sesid) => {
  return fetch(ENV.API_URL+'/users/update/building', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: _id,
        userId: _uid,
        title: _title,
        login: _login,
        pass: _pass,
        sesid: _sesid,
      }) 
    }).then(function(response) {
      return response.json();
    });
}


export const getBuildings = (_uid) => {
  return fetch(ENV.API_URL+'/users/get/building', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: _uid,
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const getAllBuildings = () => {
  return fetch(ENV.API_URL+'/users/get/buildings', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
     
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const addMessage = (_uid, _receveid, _text) => {
  return fetch(ENV.API_URL+'/message/add', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: _uid,
        receiveId: _receveid,
        text: _text,
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const getMessages = (_id) => {
  return fetch(ENV.API_URL+'/message/get/user', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: _id,
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const getMessagesList = () => {
  return fetch(ENV.API_URL+'/message/list', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
     
      }) 
    }).then(function(response) {
      return response.json();
    });
}



export const getUsers= () => {
  return fetch(ENV.API_URL+'/users/all', {
      method: 'GET'
    }).then((response) => response.json());
}

export const getUser= (_uid) => {
  return fetch(ENV.API_URL+'/users/id/'+_uid, {
      method: 'GET'
    }).then((response) => response.json());
}

export const getCitys = () => {
  return fetch(ENV.API_URL+'/citys/all', {
      method: 'GET'
    }).then((response) => response.json());
}


export const loginZWAY= (_uid) => {
  return fetch(ENV.API_URL+'/users/loginzbw/'+_uid, {
      method: 'GET'
    }).then((response) => response.json());
}

export const getCameras= (_uid) => {
  return fetch(ENV.API_URL+'/users/get/cameras/' + _uid, {
      method: 'GET'
    }).then((response) => response.json());
}

export const getEvents = (_uid, _since) => {
  return fetch(ENV.API_URL+'/events/list/'+ _uid +'/' + _since, {
      method: 'GET'
    }).then((response) => response.json());
}


 