import React, { Component } from 'react';

class UiDeviceCard extends Component {

  state = {
    switchActive: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  _getMetrics(metrics){
      let text = "";
      if(this.props.deviceType == "battery"){
        text = (metrics.level == "on" || metrics.level > 0  ? "Вкл" : "Выкл");
      }else if(this.props.deviceType == "doorlock"){
        text = (metrics.level == "on" || metrics.level > 0 ? "Вкл" : "Выкл");
      }else if(this.props.deviceType == "switchBinary (Thermostat)"){
        text = (metrics.level == "on" || metrics.level > 0 ? "Вкл" : "Выкл");
      }else if(this.props.deviceType == "switchBinary"){
        text = (metrics.level == "on" || metrics.level > 0 ? "Вкл" : "Выкл");
      }else if(this.props.deviceType == "switchMultilevel"){
        text = (metrics.level == "on" || metrics.level > 0 ? "Вкл" : "Выкл");
      }else if(this.props.deviceType == "switchMultilevel (Blinds)"){
        text = (metrics.level == "on" || metrics.level > 0 ? "Вкл" : "Выкл");
      }else if(this.props.deviceType == "sensorBinary"){
        text = (metrics.level == "on" || metrics.level > 0 ? "Вкл" : "Выкл") +"\\"+ (metrics.probeTitle );
      }else if(this.props.deviceType == "sensorMultilevel"){
        text = (metrics.level == "on" || metrics.level > 0 ? "Вкл" : "Выкл") +"\\"+ (metrics.probeTitle );
      }else if(this.props.deviceType == "toggleButton"){
        text = (metrics.level == "on" || metrics.level > 0 ? "Вкл" : "Выкл");
      }else if(this.props.deviceType == "camera"){
        text = metrics.title;
      }else if(this.props.deviceType == "switchControl"){
        text = (metrics.level == "on" || metrics.level > 0 ? "Вкл" : "Выкл");
      }else if(this.props.deviceType == "text"){
        text = metrics.title;
      }else if(this.props.deviceType == "sensorMultiline"){
        text = (metrics.level == "on" || metrics.level > 0 ? "Вкл" : "Выкл") +"\\"+ (metrics.probeTitle );
      }else if(this.props.deviceType == "switchRGB"){
        text =   "R:"+metrics.color.r+" G:"+metrics.color.g+" B:s"+metrics.color.b;
      }else if(this.props.deviceType == "switchRGBW"){
        text =   "R: "+metrics.color.r+" G: "+metrics.color.g+" B: "+metrics.color.b;
      }
  
      return text;
  }

  render() {
    return (
      <a href="" onClick={(e) => this.props.onPress(e)} className="btn card device-card">
        {this.props.deviceType == "battery" ? 
          <div className="device-card-icon-wrap">
            <img className="device-card-icon" src={require("../../../assets/img/device/battery.svg")} /> 
            <img className="device-card-icon-active" src={require("../../../assets/img/device/battery-white.svg")} />
          </div>
          :
          (this.props.deviceType == "switchRGB" ? 
            <div className="device-card-icon-wrap">
              <img className="device-card-icon" src={require("../../../assets/img/device/light.svg")} /> 
              <img className="device-card-icon-active" src={require("../../../assets/img/device/light-white.svg")} />
            </div>
            : 
            (this.props.deviceType == "switchRGBW" ? 
              <div className="device-card-icon-wrap">
                <img className="device-card-icon" src={require("../../../assets/img/device/light.svg")} /> 
                <img className="device-card-icon-active" src={require("../../../assets/img/device/light-white.svg")} />
              </div>
              :
              (this.props.deviceType == "switchControl" ? 
                <div className="device-card-icon-wrap">
                  <img className="device-card-icon" src={require("../../../assets/img/device/switch.svg")} />
                  <img className="device-card-icon-active" src={require("../../../assets/img/device/switch-white.svg")} /> 
                </div>
                :
                (this.props.deviceType == "sensorMultilevel" ? 
                  <div className="device-card-icon-wrap">
                    <img className="device-card-icon" src={require("../../../assets/img/device/sensor.svg")} /> 
                    <img className="device-card-icon-active" src={require("../../../assets/img/device/sensor-white.svg")} />
                  </div>
                  :
                  (this.props.deviceType == "sensorMultiline" ? 
                    <div className="device-card-icon-wrap">
                      <img className="device-card-icon" src={require("../../../assets/img/device/sensor.svg")} /> 
                      <img className="device-card-icon-active" src={require("../../../assets/img/device/sensor-white.svg")} />
                    </div>
                    :
                    (this.props.deviceType == "camera" ? 
                      <div className="device-card-icon-wrap">
                        <img className="device-card-icon" src={require("../../../assets/img/device/cctv.svg")} /> 
                        <img className="device-card-icon-active" src={require("../../../assets/img/device/cctv-white.svg")} />
                      </div>
                      :
                      (this.props.deviceType == "doorlock" ? 
                        <div className="device-card-icon-wrap">
                          <img className="device-card-icon" src={require("../../../assets/img/device/protection.svg")} /> 
                          <img className="device-card-icon-active" src={require("../../../assets/img/device/protection-white.svg")} />
                        </div>
                        :
                        (this.props.deviceType == "thermostat" ? 
                          <div className="device-card-icon-wrap">
                            <img className="device-card-icon" src={require("../../../assets/img/device/temperature.svg")} /> 
                            <img className="device-card-icon-active" src={require("../../../assets/img/device/temperature-white.svg")} />
                          </div>
                          :
                          (this.props.deviceType == "switchBinaryThermostat" ? 
                            <div className="device-card-icon-wrap">
                              <img className="device-card-icon" src={require("../../../assets/img/device/temperature.svg")} /> 
                              <img className="device-card-icon-active" src={require("../../../assets/img/device/temperature-white.svg")} />
                            </div>
                            :
                            (this.props.deviceType == "switchBinary" ? 
                              <div className="device-card-icon-wrap">
                                <img className="device-card-icon" src={require("../../../assets/img/device/switch.svg")} /> 
                                <img className="device-card-icon-active" src={require("../../../assets/img/device/switch-white.svg")} />
                              </div>
                              :
                              (this.props.deviceType == "sensorBinary" ? 
                                <div className="device-card-icon-wrap">
                                  <img className="device-card-icon" src={require("../../../assets/img/device/sensor.svg")} /> 
                                  <img className="device-card-icon-active" src={require("../../../assets/img/device/sensor-white.svg")} />
                                </div>
                                :
                                (this.props.deviceType == "switchMultilevel" ? 
                                  <div className="device-card-icon-wrap">
                                    <img className="device-card-icon" src={require("../../../assets/img/device/console.svg")} /> 
                                    <img className="device-card-icon-active" src={require("../../../assets/img/device/console-white.svg")} />
                                  </div>
                                  :
                                  (this.props.deviceType == "switchMultilevelBlinds" ? 
                                    <div className="device-card-icon-wrap">
                                      <img className="device-card-icon" src={require("../../../assets/img/device/console.svg")} /> 
                                      <img className="device-card-icon-active" src={require("../../../assets/img/device/console-white.svg")} />
                                    </div>
                                    :
                                    (this.props.deviceType == "toggleButton" ? 
                                      <div className="device-card-icon-wrap">
                                        <img className="device-card-icon" src={require("../../../assets/img/device/switch.svg")} /> 
                                        <img className="device-card-icon-active" src={require("../../../assets/img/device/switch-white.svg")} />
                                      </div>
                                      :
                                      (this.props.deviceType == "text" ? 
                                        <div className="device-card-icon-wrap">
                                          <img className="device-card-icon" src={require("../../../assets/img/device/smart-app.svg")} /> 
                                          <img className="device-card-icon-active" src={require("../../../assets/img/device/smart-app-white.svg")} />
                                        </div>
                                        :
                                        <div className="device-card-icon-wrap">
                                          <img className="device-card-icon" src={require("../../../assets/img/device/smarthouse.svg")} /> 
                                          <img className="device-card-icon-active" src={require("../../../assets/img/device/smarthouse-white.svg")} />
                                        </div>
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        }
        <h3 className="device-card-title">{this.props.title}</h3>
        <p className="device-card-text">{this._getMetrics(this.props.params)}</p>
        <p className="device-card-text"><span>{this.props.room}</span></p>
        <div className="status-icon">
          <button 
            onClick={() => this.setState({switchActive: !this.state.switchActive})} 
            className={"switch" + (parseInt(this.props.status) > 0 || this.props.status == "on" ? " active" : "")}
          ><div className="switch-inner"></div></button>
        </div>
      </a>
    );
  }

}

export default UiDeviceCard;